import { ProfileFamily } from "src/domain/entities/profileFamily"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	profileId: string
	father?: boolean
	mother?: boolean
	spouse?: boolean
	children?: boolean
	siblings?: boolean
	fetch?: {
		nativeVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		currentVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		surname?: {
			subCaste?: boolean
		}
	}
}

export async function profileFamilyGetApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/profile/family/get/v1"
	const requester = new ApiRequester<RequestDataShape, ProfileFamily>(endpoint)
	return await requester.sendRequest(payload, token)
}
