import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Surname } from "../../../../domain/entities/surname"
import { useAuth, useSubCasteListService } from "../../../../domain/hooks"
import { surnameAddService } from "../../../../domain/services/surname/surnameAddService"
import { FormikOnSubmit } from "../../../../utils/types"
import { ISurnameAddFormFields } from "./ISurnameAddFormFields"
import { SurnameAddDrawerFormView } from "./SurnameAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof SurnameAddDrawerFormView>,
		"handleSubmit" | "subCasteList"
	> {
	onSuccess: (surname: Surname) => void
}

export const SurnameAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISurnameAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const surname = await surnameAddService(values, token)
				onSuccess(surname)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchSubCasteList, subCasteList } = useSubCasteListService()

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	return (
		<SurnameAddDrawerFormView
			handleSubmit={handleSubmit}
			subCasteList={subCasteList}
			{...rest}
		/>
	)
}
