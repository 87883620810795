import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Village } from "../../../../domain/entities/village"
import { useAuth, useTalukaListService } from "../../../../domain/hooks"
import { villageUpdateService } from "../../../../domain/services/village/villageUpdateService"
import { FormikOnSubmit } from "../../../../utils/types"
import { IVillageUpdateFormFields } from "./IVillageUpdateFormFields"
import { VillageUpdateDrawerFormView } from "./VillageUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof VillageUpdateDrawerFormView>,
	"handleSubmit" | "talukaList"
> & {
	onSuccess?: (village: Village) => void
}

export const VillageUpdateDrawerFormController: FC<Props> = ({
	village,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IVillageUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (!village.taluka) return
				const updatedVillage = await villageUpdateService(
					{ id: village.id, talukaId: village.taluka.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedVillage)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, village.id, village.taluka],
	)
	const { fetchTalukaList, talukaList } = useTalukaListService()

	useEffect(() => {
		fetchTalukaList()
	}, [fetchTalukaList])

	return (
		<VillageUpdateDrawerFormView
			village={village}
			talukaList={talukaList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
