import { useNavigate } from "react-router-dom"
import { useAuthContext } from "../../components/context/auth"

export function useAuth() {
	const { token, setToken } = useAuthContext()
	const navigate = useNavigate()
	if (token) return { token }

	if (!token) {
		const localStorageToken = localStorage.getItem("token")

		if (localStorageToken) {
			setToken(localStorageToken)
			return { token: localStorageToken }
		} else {
			navigate("/login")
		}
	}

	throw new Error("No auth")
}
