import { useCallback, useState } from "react"
import { Taluka } from "../entities/taluka"
import { talukaListService } from "../services/taluka/talukaListService"
import { useAuth } from "./useAuth"

export function useTalukaListService(): {
	talukaList: Taluka[]
	isLoading: boolean
	error: null | Error
	fetchTalukaList: (filter?: Parameters<typeof talukaListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [talukaList, setTalukaList] = useState<Taluka[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchTalukaList = useCallback(
		async (filter: Parameters<typeof talukaListService>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await talukaListService(filter, token)
				setTalukaList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { talukaList, isLoading, error, fetchTalukaList }
}
