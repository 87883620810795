import { usePagination } from "@ajna/pagination"
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { useSearchParams } from "react-router-dom"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { SubCaste } from "src/domain/entities/subCaste"
import { ReviewStatus } from "src/utils/enums"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { useRegistrationListService, useSubCasteListService } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { RegistrationListController } from "./RegistrationList"

export const RegistrationsPage: FC = () => {
	const { registrationList, isLoading, fetchRegistrationList } =
		useRegistrationListService()

	const [params, setParams] = useSearchParams()

	const { subCasteList, fetchSubCasteList } = useSubCasteListService()

	const [selectedSubCaste, setSelectedSubCaste] = useState<SubCaste>()

	const queryReviewStatus = params.get("reviewStatus")
	const [selectedReviewStatus, setSelectedReviewStatus] = useState<
		ReviewStatus | undefined
	>(queryReviewStatus ? (queryReviewStatus as ReviewStatus) : undefined)

	const queryPage = params.get("page")
	const pagination = usePagination({
		initialState: { currentPage: +(queryPage ?? 1), pageSize: 15 },
	})

	const querySearch = params.get("search")
	const [searchText, setSearchText] = useState(querySearch ?? "")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchRegistrations = useCallback(async () => {
		setParams("page=" + pagination.currentPage)
		if (searchText) setParams("search=" + searchText)
		if (selectedReviewStatus) setParams("reviewStatus=" + selectedReviewStatus)

		return await fetchRegistrationList({
			fetch: {
				surname: true,
				reviewer: true,
				reviewerDetails: true,
				reviews: true,
				nativeVillage: true,
				user: true,
			},
			reviewStatus: selectedReviewStatus,
			search: searchText,
			subCasteId: selectedSubCaste?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [
		fetchRegistrationList,
		pagination.currentPage,
		selectedSubCaste?.id,
		searchText,
		selectedReviewStatus,
		setParams,
	])

	useEffect(() => {
		fetchRegistrations()
	}, [fetchRegistrations])

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	const subCasteOptions: {
		label: string
		value?: SubCaste
	}[] = subCasteList.map((subCaste) => ({
		label: subCaste.name.en,
		value: subCaste,
	}))

	subCasteOptions.unshift({
		label: "All",
		value: undefined,
	})

	const reviewStatusOptions: {
		label: string
		value?: ReviewStatus
	}[] = Object.values(ReviewStatus).map((status) => ({
		label: status.toUpperCase(),
		value: status,
	}))

	reviewStatusOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Registrations
					</Text>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box
						width={{ base: "full", lg: "fit-content" }}
						mb={{ base: "2", lg: "0" }}
					>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Sub Caste */}
					<Box width={{ base: "full", lg: "20%" }}>
						<InputLabel label="Sub Caste" />
						<ReactSelect
							name="subCasteId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedSubCaste(val?.value)
							}}
							value={subCasteOptions.find(
								(el) => el.value?.id === selectedSubCaste?.id,
							)}
							options={subCasteOptions}
						/>
					</Box>
					{/* Status */}
					<Box width={{ base: "100%", lg: "20%" }} mt={{ base: "2", lg: "0" }}>
						<InputLabel label="Status" />
						<ReactSelect
							name="reviewStatus"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedReviewStatus(val?.value)
							}}
							value={reviewStatusOptions.find(
								(el) => el.value === selectedReviewStatus,
							)}
							options={reviewStatusOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<RegistrationListController list={registrationList} isLoading={isLoading} />
			<CustomPagination
				pagination={pagination}
				isNextDisabled={registrationList.length === 0}
			/>
		</DashboardWrapper>
	)
}
