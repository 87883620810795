import { usePagination } from "@ajna/pagination"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FC, useCallback, useEffect } from "react"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { User } from "src/domain/entities/user"
import { userUpdateService } from "src/domain/services/user/userUpdateService"

import { useAuth, useUserListService } from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { UserListController } from "./UserList"

export const UsersPage: FC = () => {
	const { userList, isLoading, fetchUserList } = useUserListService()
	const { token } = useAuth()

	const pagination = usePagination({
		initialState: { currentPage: 1, pageSize: 15 },
	})

	const fetchUsers = useCallback(async () => {
		return await fetchUserList({
			fetch: { registration: true },
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [fetchUserList, pagination.currentPage])

	const handleUserReviewerChange = useCallback(
		async (val: boolean, user: User) => {
			if (!token) return
			await userUpdateService(
				{
					id: user.id,
					update: { isReviewer: val },
				},
				token,
			)

			await fetchUsers()
		},
		[token, fetchUsers],
	)

	useEffect(() => {
		fetchUsers()
	}, [fetchUsers])

	return (
		<DashboardWrapper>
			<Box padding={2}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Users
					</Text>
				</Flex>
			</Box>
			<UserListController
				list={userList}
				isLoading={isLoading}
				handleUserReviewerChange={handleUserReviewerChange}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={userList.length === 0}
			/>
		</DashboardWrapper>
	)
}
