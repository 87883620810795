import { FC } from "react"
import { Village } from "../../../../domain/entities/village"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { VillageListView } from "./VillageListView"

interface Props {
	list: Village[]
	isLoading: boolean
	onUpdate: (village: Village) => void
	onDelete: (village: Village) => void
	onAddClick: () => void
}

export const VillageListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Villages found." onAddClick={onAddClick} />
	}

	return <VillageListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
