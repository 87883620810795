import {
	Flex,
	Image,
	Switch,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsPerson } from "react-icons/bs"
import { formatDate } from "src/utils/helpers"
import { User } from "../../../../domain/entities/user"

export const UserListView: FC<{
	list: User[]
	handleUserReviewerChange: (val: boolean, user: User) => Promise<void>
}> = ({ list, handleUserReviewerChange }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Avatar</Th>
				<Th>Auth Method</Th>
				<Th>Name</Th>
				<Th>Is Reviewer</Th>
				<Th>Created At</Th>
				<Th>Updated At</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((user, i) => (
				<Tr key={i}>
					<Td>
						{user.registration?.identity?.identityProofUrl ? (
							<Image
								style={{ aspectRatio: "1" }}
								src={user.registration?.identity?.identityProofUrl}
								height="50px"
								rounded={"lg"}
							/>
						) : (
							<Flex
								height={50}
								width={50}
								bgColor="gray.100"
								rounded={"lg"}
								justify="center"
								align={"center"}
								color="gray.500"
							>
								<BsPerson size={20} />
							</Flex>
						)}
					</Td>
					<Td>{user.authMethod || "N/A"}</Td>
					<Td>
						<Text fontWeight={500}>
							{user.registration?.basic?.firstName?.en}{" "}
							{user.registration?.basic?.surname?.name.en}
						</Text>
						<Text>
							{user.registration?.basic?.firstName?.gu}{" "}
							{user.registration?.basic?.surname?.name.gu}
						</Text>
					</Td>
					<Td>
						<Switch
							isChecked={user.isReviewer}
							onChange={(val) => {
								handleUserReviewerChange(val.target.checked, user)
							}}
						/>
					</Td>
					<Td>{formatDate(user.createdAt, { includeTime: true })}</Td>
					<Td>
						{user.createdAt !== user.updatedAt
							? formatDate(user.updatedAt, {
									includeTime: true,
							  })
							: "-"}
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
