import { FC } from "react"
import { State } from "../../../../domain/entities/state"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { StateListView } from "./StateListView"

interface Props {
	list: State[]
	isLoading: boolean
	onUpdate: (state: State) => void
	onDelete: (state: State) => void
	onAddClick: () => void
}

export const StateListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No States found." onAddClick={onAddClick} />
	}

	return <StateListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
