import { registrationProfileUnlinkApi } from "../../api/registration/registrationProfileUnlinkApi"

type PayloadShape = Parameters<typeof registrationProfileUnlinkApi>[0]

export async function registrationProfileUnlinkService(
	payload: PayloadShape,
	token: string,
) {
	return await registrationProfileUnlinkApi(payload, token)
}
