import { useCallback, useState } from "react"
import { District } from "../entities/district"
import { districtListService } from "../services/district/districtListService"
import { useAuth } from "./useAuth"

export function useDistrictListService(): {
	districtList: District[]
	isLoading: boolean
	error: null | Error
	fetchDistrictList: (
		filter?: Parameters<typeof districtListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [districtList, setDistrictList] = useState<District[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchDistrictList = useCallback(
		async (filter: Parameters<typeof districtListService>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await districtListService(filter, token)
				setDistrictList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { districtList, isLoading, error, fetchDistrictList }
}
