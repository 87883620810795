import { MultiLangText } from "src/utils/types"
import { Surname } from "../../entities/surname"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	code?: string
	name: MultiLangText
	subCasteId: string
}

export async function surnameAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/surname/add/v1"
	const requester = new ApiRequester<RequestDataShape, Surname>(endpoint)
	return await requester.sendRequest(payload, token)
}
