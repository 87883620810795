import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import { ICountryUpdateFormFields } from "."
import { Country } from "../../../../domain/entities/country"
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	country: Country
	handleSubmit: FormikOnSubmit<ICountryUpdateFormFields>
}

export const CountryUpdateDrawerFormView: FC<Props> = ({
	country,
	handleSubmit,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<ICountryUpdateFormFields>
			initialValues={{
				name: country.name,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Country"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								<Box flex={5}>
									{/* Name */}
									<FormControl>
										<InputLabel label="Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.name?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>
								{/* Name Gu */}
								<FormControl flex={5}>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.name?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
