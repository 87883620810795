import { ComponentProps, FC, useCallback } from "react"
import { Country } from "../../../../domain/entities/country"
import { useAuth } from "../../../../domain/hooks"
import { countryUpdateService } from "../../../../domain/services/country/countryUpdateService"
import { FormikOnSubmit } from "../../../../utils/types"
import { ICountryUpdateFormFields } from "./ICountryUpdateFormFields"
import { CountryUpdateDrawerFormView } from "./CountryUpdateDrawerFormView"

type Props = Omit<ComponentProps<typeof CountryUpdateDrawerFormView>, "handleSubmit"> & {
	onSuccess?: (country: Country) => void
}

export const CountryUpdateDrawerFormController: FC<Props> = ({
	country,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ICountryUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedCountry = await countryUpdateService(
					{ id: country.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedCountry)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, country.id],
	)

	return (
		<CountryUpdateDrawerFormView
			country={country}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
