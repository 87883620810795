import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Taluka } from "../../../../domain/entities/taluka"
import { useAuth, useDistrictListService } from "../../../../domain/hooks"
import { talukaUpdateService } from "../../../../domain/services/taluka/talukaUpdateService"
import { FormikOnSubmit } from "../../../../utils/types"
import { ITalukaUpdateFormFields } from "./ITalukaUpdateFormFields"
import { TalukaUpdateDrawerFormView } from "./TalukaUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof TalukaUpdateDrawerFormView>,
	"handleSubmit" | "districtList"
> & {
	onSuccess?: (taluka: Taluka) => void
}

export const TalukaUpdateDrawerFormController: FC<Props> = ({
	taluka,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ITalukaUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (!taluka.district?.id) return
				const updatedTaluka = await talukaUpdateService(
					{ id: taluka.id, districtId: taluka.district.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedTaluka)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, taluka.id, taluka.district?.id],
	)
	const { fetchDistrictList, districtList } = useDistrictListService()

	useEffect(() => {
		fetchDistrictList()
	}, [fetchDistrictList])

	return (
		<TalukaUpdateDrawerFormView
			taluka={taluka}
			districtList={districtList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
