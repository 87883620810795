import { FC } from "react"
import { Taluka } from "../../../../domain/entities/taluka"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { TalukaListView } from "./TalukaListView"

interface Props {
	list: Taluka[]
	isLoading: boolean
	onUpdate: (taluka: Taluka) => void
	onDelete: (taluka: Taluka) => void
	onAddClick: () => void
}

export const TalukaListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Talukas found." onAddClick={onAddClick} />
	}

	return <TalukaListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
