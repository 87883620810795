import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	registrationId: string
}

export async function registrationProfileUnlinkApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/registration/profile/unlink/v1"
	const requester = new ApiRequester<RequestDataShape, void>(endpoint)
	return await requester.sendRequest(payload, token)
}
