import { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import { User } from "src/domain/entities/user"
import { userListService } from "src/domain/services/user/userListService"
import { useAuthContext } from "./auth"

interface ICoreState {
	user: User | null
	setUser: Function
}

const CoreContext = createContext<ICoreState>({
	user: null,
	setUser: () => {},
})

export const CoreContextProvider: FC<{ children?: JSX.Element }> = ({
	children,
	...rest
}) => {
	const { token } = useAuthContext()
	const [user, setStateUser] = useState<ICoreState["user"]>(null)

	const fetchUserDetails = useCallback(async () => {
		if (!token) return

		const userList = await userListService(
			{ fetch: { profile: true, profileDetails: true } },
			token,
		)

		if (!userList?.length) return

		setStateUser(userList[0])
	}, [token, setStateUser])

	useEffect(() => {
		fetchUserDetails()
	}, [fetchUserDetails])

	const setUser = (value: User | null) => {
		setStateUser(value)
	}

	return (
		<CoreContext.Provider value={{ user, setUser }} {...rest}>
			{children}
		</CoreContext.Provider>
	)
}

export function useCoreContext() {
	return useContext(CoreContext)
}
