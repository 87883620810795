import axios from "axios"
import _ from "lodash"
import moment from "moment"
import { Profile } from "src/domain/entities/profile"
import { Village } from "src/domain/entities/village"
import { Language } from "./enums"

export function toINR(amount: number, { dashIfZero = false, gujarati = false } = {}) {
	if (dashIfZero && amount === 0) return "-"
	const formatted = new Intl.NumberFormat("gu-IN", {
		style: "currency",
		currency: "INR",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount)

	if (gujarati) {
		let formattedGujarati = formatted.split("").map(getGujaratiCharacter)
		return formattedGujarati
	}

	return formatted
}

export function toKGs(quantity: number, { dashIfZero = false } = {}) {
	if (dashIfZero && quantity === 0) return "-"
	const formatted = new Intl.NumberFormat("en-IN", {
		style: "decimal",
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	}).format(quantity)
	return formatted
}

export function fullMonthAndYear({ month, year }: { year: number; month: number }) {
	return monthName(month) + " " + year
}

export function monthName(month: number) {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]
	return months[month - 1]
}

export function disclosureDefaultValue() {
	return {
		isOpen: false,
		onOpen: () => {},
		onClose: () => {},
		onToggle: () => {},
		getButtonProps: () => {},
		getDisclosureProps: () => {},
		isControlled: false,
	}
}

export function getErrorMessage(err: unknown) {
	if (err instanceof Error) return err.message
	if (typeof err === "string") return err
	return "Unknown Error"
}

export function formatDate(
	date: Date | number | string,
	{ gujarati = false, includeTime = false } = {},
) {
	const formattedDate = moment(date).format(
		"DD-MM-YYYY" + (includeTime ? " hh:mm:ss" : ""),
	)

	if (gujarati) {
		let formattedGujarati = formattedDate.split("").map(getGujaratiCharacter)
		return formattedGujarati
	}

	return formattedDate
}

export function getFullName(
	val?: Profile,
	{
		lang = Language.ENGLISH,
	}: {
		lang?: Language
	} = {
		lang: Language.ENGLISH,
	},
) {
	let name = val?.firstName?.[lang] || ""

	if (val?.middleName) name += " " + val.middleName[lang]

	if (val?.surname) {
		name += " " + val.surname.name?.[lang]
	}

	return name
}
export function getVillageName(
	val?: Village,
	{
		lang,
		depth,
	}: {
		lang?: Language
		depth?: number
	} = {
		lang: Language.ENGLISH,
		depth: 5,
	},
) {
	if (!lang) lang = Language.ENGLISH
	if (!depth) depth = 5

	let name = val?.name[lang] || ""

	if (val?.taluka && depth > 1) name += ", " + val.taluka.name[lang]
	if (val?.taluka?.district && depth > 2) name += ", " + val.taluka.district.name[lang]
	if (val?.taluka?.district?.state && depth > 3)
		name += ", " + val.taluka.district.state.name[lang]
	if (val?.taluka?.district?.state?.country && depth > 4)
		name += ", " + val.taluka.district.state.country.name[lang]

	return name
}

export async function fetchGujaratiSuggestions(text: string): Promise<string[]> {
	if (text.trim().length === 0) return []
	var response = await axios.get(
		`https://inputtools.google.com/request?text=${text}&itc=gu-t-i0-und&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8`,
	)
	if (_.isArray(response.data[1][0][1])) {
		return response.data[1][0][1]
	}
	return []
}

export function getGujaratiCharacter(char?: string) {
	switch (char) {
		case "1":
			return "૧"
		case "2":
			return "૨"
		case "3":
			return "૩"
		case "4":
			return "૪"
		case "5":
			return "૫"
		case "6":
			return "૬"
		case "7":
			return "૭"
		case "8":
			return "૮"
		case "9":
			return "૯"
		case "0":
			return "૦"
		default:
			return char
	}
}

export const getGujaratiString = (str?: string) => {
	if (!str) return ""
	return str.split("").map(getGujaratiCharacter)
}

export function gujaratiToDecimal(n: string): number {
	return n.split("").reduce((sum, chr, index, array) => {
		var num = chr.charCodeAt(0) - 2790 //Subtract 2790 to get the decimal value for the current char
		num *= Math.pow(10, array.length - index - 1) //Multiply it with a power of ten, based on its position
		return sum + num //Sum it up
	}, 0)
}

export function decimalToGujarati(n: number): string {
	var str = ""
	var decimal = n
	while (decimal > 0) {
		var digit = decimal % 10
		str = String.fromCharCode(digit + 2790) + str
		decimal = Math.floor(decimal / 10)
	}

	return str
}

export function toINRWords(num: number, { gujarati = false } = {}) {
	let price = num.toString()
	var sglDigit = gujarati
			? ["શૂન્ય", "એક", "બે", "ત્રણ", "ચાર", "પાંચ", "છ", "સાત", "આઠ", "નવ"]
			: [
					"Zero",
					"One",
					"Two",
					"Three",
					"Four",
					"Five",
					"Six",
					"Seven",
					"Eight",
					"Nine",
			  ],
		dblDigit = gujarati
			? [
					"દસ",
					"અગિયાર",
					"બાર",
					"તેર",
					"ચૌદ",
					"પંદર",
					"સોળ",
					"સત્તર",
					"અઢાર",
					"ઓગણીસ",
			  ]
			: [
					"Ten",
					"Eleven",
					"Twelve",
					"Thirteen",
					"Fourteen",
					"Fifteen",
					"Sixteen",
					"Seventeen",
					"Eighteen",
					"Nineteen",
			  ],
		tensPlace = gujarati
			? [
					"",
					"દસ",
					"વીસ",
					"ત્રીસ",
					"ચાલીસ",
					"પચાસ",
					"સાંઠ",
					"સીતેર",
					"એંસી",
					"નેવું",
			  ]
			: [
					"",
					"Ten",
					"Twenty",
					"Thirty",
					"Forty",
					"Fifty",
					"Sixty",
					"Seventy",
					"Eighty",
					"Ninety",
			  ],
		handle_tens = function (dgt: number, prevDgt: number) {
			return 0 === dgt ? "" : " " + (1 === dgt ? dblDigit[prevDgt] : tensPlace[dgt])
		},
		handle_utlc = function (dgt: number, nxtDgt: number, denom: string) {
			return (
				(0 !== dgt && 1 !== nxtDgt ? " " + sglDigit[dgt] : "") +
				(0 !== nxtDgt || dgt > 0 ? " " + denom : "")
			)
		}

	var str = "",
		digitIdx = 0,
		digit = 0,
		nxtDigit = 0,
		words: string[] = []
	var re = /(0|([1-9]\d*))(\.\d+)?/g
	if (re.test(price)) {
		var arr = price.split(".")
		price = arr[0]
		for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
			switch (
				((digit = +price[digitIdx] - 0),
				(nxtDigit = digitIdx > 0 ? +price[digitIdx - 1] - 0 : 0),
				price.length - digitIdx - 1)
			) {
				case 0:
					words.push(handle_utlc(digit, nxtDigit, ""))
					break
				case 1:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break
				case 2:
					words.push(
						0 !== digit
							? " " +
									sglDigit[digit] +
									(gujarati ? " સો" : " Hundred") +
									(0 !== +price[digitIdx + 1] &&
									0 !== +price[digitIdx + 2]
										? gujarati
											? " અને"
											: " and"
										: "")
							: "",
					)
					break
				case 3:
					words.push(
						handle_utlc(digit, nxtDigit, gujarati ? "હજાર" : "Thousand"),
					)
					break
				case 4:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break
				case 5:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "લાખ" : "Lakh"))
					break
				case 6:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break
				case 7:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "કરોડ" : "Crore"))
					break
				case 8:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 9:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "અરબ" : "Arab"))
					break
				case 10:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 11:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "ખરબ" : "Kharab"))
					break
				case 12:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 13:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "નીલ" : "Nil"))
					break
				case 14:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 15:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "પદ્મ" : "Padma"))
					break
				case 16:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 17:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "શંખ" : "Shankh"))
					break
				case 18:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break

				case 19:
					words.push(
						0 !== digit
							? " " +
									sglDigit[digit] +
									(gujarati ? " સો" : " Hundred") +
									(0 !== +price[digitIdx + 1] &&
									0 !== +price[digitIdx + 2]
										? gujarati
											? " અને"
											: " and"
										: "")
							: "",
					)
					break
				case 20:
					words.push(
						handle_utlc(digit, nxtDigit, gujarati ? "હજાર" : "Thousand"),
					)
					break
				case 21:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break
				case 22:
					words.push(handle_utlc(digit, nxtDigit, gujarati ? "લાખ" : "Lakh"))
					break
				case 23:
					words.push(handle_tens(digit, +price[digitIdx + 1]))
					break
			}
		}
		str = words.reverse().join("")
		if (arr.length > 1) {
			price = arr[1]
			words = []
			for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
				switch (
					((digit = +price[digitIdx] - 0),
					(nxtDigit = digitIdx > 0 ? +price[digitIdx - 1] - 0 : 0),
					price.length - digitIdx - 1)
				) {
					case 0:
						words.push(handle_utlc(digit, nxtDigit, ""))
						break
					case 1:
						words.push(handle_tens(digit, +price[digitIdx + 1]))
						break
					case 2:
						words.push(
							0 !== digit
								? " " +
										sglDigit[digit] +
										(gujarati ? " સો" : " Hundred") +
										(0 !== +price[digitIdx + 1] &&
										0 !== +price[digitIdx + 2]
											? gujarati
												? " અને"
												: " and"
											: "")
								: "",
						)
						break
				}
			}
			str +=
				(gujarati ? " રૂપિયા " : " Rupees ") +
				words.reverse().join("") +
				(gujarati ? " પૈસા" : " Paise")
		}
	} else str = ""
	return str
}
