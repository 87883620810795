import { MultiLangText } from "src/utils/types"
import { Country } from "../../entities/country"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
}

export async function countryAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/country/add/v1"
	const requester = new ApiRequester<RequestDataShape, Country>(endpoint)
	return await requester.sendRequest(payload, token)
}
