import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthContextProvider } from "./components/context/auth"
import { CoreContextProvider } from "./components/context/core"
import { LoginPage, LogoutPage, NotFoundPage } from "./components/pages"
import { CountriesPage } from "./components/pages/CountriesPage"
import { DistrictsPage } from "./components/pages/DistrictsPage"
import { HomePage } from "./components/pages/HomePage"
import { RegistrationsPage } from "./components/pages/RegistrationsPage"
import { RegistrationDetailsController } from "./components/pages/RegistrationsPage/RegistrationDetails"
import { StatesPage } from "./components/pages/StatesPage"
import { SurnamesPage } from "./components/pages/SurnamesPage"
import { TalukasPage } from "./components/pages/TalukasPage"
import { UsersPage } from "./components/pages/UsersPage"
import { VillagesPage } from "./components/pages/VillagesPage"
import { PrivateRoute } from "./components/shared/PrivateRoute"

export const App: React.FC = () => (
	<ChakraProvider>
		<AuthContextProvider>
			<CoreContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/logout" element={<LogoutPage />} />
						<Route
							path="/"
							element={<PrivateRoute element={<HomePage />} />}
						/>

						<Route
							path="/surnames"
							element={<PrivateRoute element={<SurnamesPage />} />}
						/>

						<Route
							path="/countries"
							element={<PrivateRoute element={<CountriesPage />} />}
						/>

						<Route
							path="/states"
							element={<PrivateRoute element={<StatesPage />} />}
						/>

						<Route
							path="/districts"
							element={<PrivateRoute element={<DistrictsPage />} />}
						/>

						<Route
							path="/talukas"
							element={<PrivateRoute element={<TalukasPage />} />}
						/>

						<Route
							path="/villages"
							element={<PrivateRoute element={<VillagesPage />} />}
						/>

						<Route
							path="/registrations"
							element={<PrivateRoute element={<RegistrationsPage />} />}
						/>

						<Route
							path="/registrations/:id"
							element={
								<PrivateRoute
									element={<RegistrationDetailsController />}
								/>
							}
						/>

						<Route
							path="/users"
							element={<PrivateRoute element={<UsersPage />} />}
						/>

						<Route element={<NotFoundPage />} />
					</Routes>
				</BrowserRouter>
			</CoreContextProvider>
		</AuthContextProvider>
	</ChakraProvider>
)
