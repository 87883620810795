import { MultiLangText } from "src/utils/types"
import { Surname } from "../../entities/surname"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		code?: string
		name?: MultiLangText
		isApproved?: boolean
		subCasteId?: string
	}
}

export async function surnameUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/surname/update/v1"
	const requester = new ApiRequester<RequestDataShape, Surname>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
