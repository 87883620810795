import { Box, Flex, FormControl, Input, Stack, Tag } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { Taluka } from "src/domain/entities/taluka"
import { IVillageUpdateFormFields } from "."
import { Village } from "../../../../domain/entities/village"
import { fetchGujaratiSuggestions } from "../../../../utils/helpers"
import { FormikOnSubmit } from "../../../../utils/types"
import { DrawerForm, ErrorMessageField } from "../../../ui"
import { InputLabel } from "../../../ui/InputLabel"

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	village: Village
	handleSubmit: FormikOnSubmit<IVillageUpdateFormFields>
	talukaList: Taluka[]
}

export const VillageUpdateDrawerFormView: FC<Props> = ({
	village,
	handleSubmit,
	talukaList,
	...rest
}) => {
	const [nameSuggestions, setNameSuggestions] = useState<string[]>([])

	return (
		<Formik<IVillageUpdateFormFields>
			initialValues={{
				name: village.name,
				talukaId: village.taluka?.id,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const handleNameChange = async (
					e: React.ChangeEvent<HTMLInputElement>,
				) => {
					setFieldValue("name.en", e.target.value)
					const suggestions = await fetchGujaratiSuggestions(e.target.value)
					setNameSuggestions(suggestions)
				}

				const talukaOptions = talukaList.map((taluka) => ({
					value: taluka.id,
					label: taluka.name.en + ` (${taluka.name.gu})`,
				}))

				return (
					<DrawerForm
						size="sm"
						headerLabel="Add Village"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							{/* Taluka */}
							<FormControl flex={2}>
								<InputLabel label="Taluka" />
								<ReactSelect
									name="talukaId"
									onChange={(newValue) => {
										setFieldValue(
											"talukaId",
											(newValue as SelectOption).value,
										)
									}}
									value={talukaOptions.find(
										(el) => el.value === values.talukaId,
									)}
									options={talukaOptions}
									isSearchable
								/>
							</FormControl>
							<Flex gridGap={2}>
								<Box>
									{/* Name */}
									<FormControl>
										<InputLabel label="Village Name" />
										<Input
											name="name.en"
											placeholder="Name"
											maxLength={50}
											required
											autoFocus
											value={values.name?.en}
											onChange={handleNameChange}
										/>
										<ErrorMessage
											component={ErrorMessageField}
											name="name.en"
										/>
									</FormControl>
									<Box>
										{nameSuggestions.map((el, i) => (
											<Tag
												colorScheme={"green"}
												backgroundColor={"green.50"}
												variant="outline"
												_hover={{
													backgroundColor: "green.100",
												}}
												cursor="pointer"
												margin={0.5}
												onClick={() => {
													setFieldValue("name.gu", el)
													setNameSuggestions([])
												}}
												key={i}
											>
												{el}
											</Tag>
										))}
									</Box>
								</Box>

								{/* Name Gu */}
								<FormControl>
									<InputLabel label="Name Gujarati" />
									<Input
										name="name.gu"
										placeholder="Name Gujarati"
										maxLength={50}
										required
										value={values.name?.gu}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="name.gu"
									/>
								</FormControl>
							</Flex>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
