import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Registration } from "../../../../domain/entities/registration"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { RegistrationListView } from "./RegistrationListView"

interface Props {
	list: Registration[]
	isLoading: boolean
}

export const RegistrationListController: FC<Props> = ({ isLoading, list }) => {
	const navigate = useNavigate()
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Registrations found." />
	}

	const handleRegistrationClick = (registration: Registration) => {
		navigate(`/registrations/${registration.id}`)
	}

	return (
		<RegistrationListView list={list} onRegistrationClick={handleRegistrationClick} />
	)
}
