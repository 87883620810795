import { AuthMethod, Language } from "src/utils/enums"
import { Profile } from "./profile"
import { Registration } from "./registration"

export interface User {
	id: string
	isSuspended: boolean
	isReviewer: boolean
	lang: null | Language
	hashedPassword?: null | string
	authMethod?: AuthMethod
	registration?: Registration
	profile?: Profile
	createdAt: number
	updatedAt: number
}

export function getUserFullName(
	user?: User,
	lang: "en" | "gu" = "en",
): string | undefined {
	if (!user) return

	if (user.profile) {
		const { firstName, surname } = user.profile
		if (surname) {
			return `${firstName[lang]} ${surname.name[lang]}`
		}
		return firstName[lang]
	}
	if (user.registration?.basic) {
		const { firstName, surname, customSurname } = user.registration.basic
		if (surname) {
			return `${firstName[lang]} ${surname.name[lang]}`
		}
		if (customSurname) {
			return `${firstName[lang]} ${customSurname.text?.[lang]}`
		}
		return firstName[lang]
	}
	return "Unknown"
}
