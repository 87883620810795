import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Surname } from "../../../../domain/entities/surname"
import { useAuth, useSubCasteListService } from "../../../../domain/hooks"
import { surnameUpdateService } from "../../../../domain/services/surname/surnameUpdateService"
import { FormikOnSubmit } from "../../../../utils/types"
import { ISurnameUpdateFormFields } from "./ISurnameUpdateFormFields"
import { SurnameUpdateDrawerFormView } from "./SurnameUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof SurnameUpdateDrawerFormView>,
	"handleSubmit" | "subCasteList"
> & {
	onSuccess?: (surname: Surname) => void
}

export const SurnameUpdateDrawerFormController: FC<Props> = ({
	surname,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ISurnameUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				const updatedSurname = await surnameUpdateService(
					{ id: surname.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedSurname)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, surname.id],
	)

	const { fetchSubCasteList, subCasteList } = useSubCasteListService()

	useEffect(() => {
		fetchSubCasteList()
	}, [fetchSubCasteList])

	return (
		<SurnameUpdateDrawerFormView
			surname={surname}
			subCasteList={subCasteList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
