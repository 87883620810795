import { MultiLangText } from "src/utils/types"
import { District } from "../../entities/district"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	stateId: string
}

export async function districtAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/district/add/v1"
	const requester = new ApiRequester<RequestDataShape, District>(endpoint)
	return await requester.sendRequest(payload, token)
}
