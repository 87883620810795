import {
	Button,
	Flex,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
	useToast,
} from "@chakra-ui/react"
import { FC, useCallback, useState } from "react"
import { Registration } from "src/domain/entities/registration"
import { DomainError } from "src/domain/errors"
import { useAuth } from "src/domain/hooks"
import { reviewRejectService } from "src/domain/services/review/reviewRejectService"

interface Props {
	registration: Registration
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const RejectReviewDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	registration,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const [messageEn, setMessageEn] = useState("")
	const [messageGu, setMessageGu] = useState("")

	const handleRejectReview = useCallback(async () => {
		try {
			setIsLoading(true)
			await reviewRejectService(
				{
					registrationId: registration.id,
					note: {
						en: messageEn,
						gu: messageGu,
					},
				},
				token,
			)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			if (err instanceof DomainError) {
				toast({
					title: err.name,
					description: err.message,
					status: "error",
					position: "bottom-right",
					isClosable: true,
					duration: 10000,
				})
			}
		} finally {
			setIsLoading(false)
		}
	}, [registration, setIsOpen, token, messageEn, messageGu, onSuccess, toast])

	return (
		<Modal isOpen={isOpen} size="2xl" onClose={() => setIsOpen(false)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Reject Review</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex gridColumnGap={2}>
						<FormControl>
							<label>Message En</label>
							<Textarea
								placeholder="Message En"
								value={messageEn}
								onChange={(e) => setMessageEn(e.target.value)}
							/>
						</FormControl>
						<FormControl>
							<label>Message Gu</label>
							<Textarea
								placeholder="Message Gu"
								value={messageGu}
								onChange={(e) => setMessageGu(e.target.value)}
							/>
						</FormControl>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Flex gridColumnGap={2}>
						<Button onClick={() => setIsOpen(false)}>Close</Button>

						<Button
							colorScheme={"red"}
							isLoading={isLoading}
							onClick={handleRejectReview}
						>
							Reject
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
