import axios, { AxiosError, AxiosRequestHeaders } from "axios"
import { BASE_API_URL } from "../constants"
import {
	DomainError,
	ForbiddenError,
	NotFoundError,
	OperationError,
	UnauthorisedError,
	UnexpectedError,
	ValidationError,
} from "../errors"

export type ApiMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE"

export class ApiRequester<RequestShape, ResponseShape> {
	endpoint: string
	method: ApiMethod

	constructor(endpoint: string, method: ApiMethod = "POST") {
		this.endpoint = endpoint
		this.method = method
	}

	async sendRequest(
		requestBody: RequestShape,
		token?: null | string,
	): Promise<ResponseShape> {
		const url = `${BASE_API_URL}${this.endpoint}`
		const headers: AxiosRequestHeaders = token
			? { Authorization: `user_reviewer 1 ${token}` }
			: {}

		const params = {
			client: "browser",
			lang: "en",
			buildNo: "1",
		}

		try {
			const response =
				this.method === "POST"
					? await axios.post(url, requestBody, { headers, params })
					: await axios.get(url, { data: requestBody, params })
			return response.data as ResponseShape
		} catch (err: unknown) {
			throw this.getDomainError(err as AxiosError)
		}
	}

	private getDomainError(err: AxiosError): DomainError {
		if (err.response) {
			const message =
				(err.response.data as any)?.message ?? "No error message provided"

			if (err.response.status === 400) {
				throw new ValidationError(message)
			} else if (err.response.status === 401) {
				window.location.href = "/logout"
				throw new UnauthorisedError(message)
			} else if (err.response.status === 403) {
				throw new ForbiddenError(message)
			} else if (err.response.status === 404) {
				console.error(err.response.data)
				throw new NotFoundError()
			} else if (err.response.status === 422) {
				throw new OperationError(message)
			} else if (err.response.status === 500) {
				console.error(err.response.data)
				// throw new ServerError()
				throw new OperationError(message)
			}
		}

		throw new UnexpectedError(err)
	}
}
