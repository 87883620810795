import { profileFamilyGetApi } from "../../api/profileFamily/profileFamilyGetApi"

type PayloadShape = Parameters<typeof profileFamilyGetApi>[0]

export async function profileFamilyGetService(
	payload: PayloadShape,
	token?: null | string,
) {
	const profileFamily = await profileFamilyGetApi(payload, token)
	return profileFamily
}
