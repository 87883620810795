import { MultiLangText } from "src/utils/types"
import { Taluka } from "../../entities/taluka"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	districtId: string
	update: {
		name?: MultiLangText
		sameAsParent?: boolean
		districtId?: string
		isApproved?: boolean
	}
}

export async function talukaUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/taluka/update/v1"
	const requester = new ApiRequester<RequestDataShape, Taluka>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
