import { FC, useCallback, useState } from "react"
import { District } from "src/domain/entities/district"
import { useAuth } from "../../../domain/hooks"
import { districtDeleteService } from "../../../domain/services/district/districtDeleteService"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	district: District
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const DistrictDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	district,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleDistrictDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await districtDeleteService({ id: district.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [district, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete District: ${district.name.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleDistrictDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
