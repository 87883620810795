import { useCallback, useState } from "react"
import { User } from "../entities/user"
import { userListService } from "../services/user/userListService"
import { useAuth } from "./useAuth"

export function useUserListService(): {
	userList: User[]
	isLoading: boolean
	error: null | Error
	fetchUserList: (filter?: Parameters<typeof userListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [userList, setUserList] = useState<User[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const fetchUserList = useCallback(
		async (filter: Parameters<typeof userListService>[0] = {}) => {
			try {
				setIsLoading(true)
				const data = await userListService(filter, token)
				setUserList(data)
			} catch (error) {
				setError(error as Error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)

	return { userList, isLoading, error, fetchUserList }
}
