import { Mobile } from "src/domain/entities/mobile"
import { MobileNumber } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	userId?: string
	mobile?: MobileNumber
}

export async function mobileListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/mobile/list/v1"
	const requester = new ApiRequester<RequestDataShape, Mobile[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
