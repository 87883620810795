import { Flex, FormControl, Input, Stack } from "@chakra-ui/react"
import { ErrorMessage, Formik } from "formik"
import { ComponentProps, FC, useState } from "react"
import ReactSelect from "react-select"
import { SelectOption } from "src/components/shared/ReactSelect"
import { DrawerForm, ErrorMessageField } from "src/components/ui"
import { InputLabel } from "src/components/ui/InputLabel"
import { Registration } from "src/domain/entities/registration"
import { Village } from "src/domain/entities/village"
import { Language } from "src/utils/enums"
import { getVillageName } from "src/utils/helpers"
import { FormikOnSubmit } from "src/utils/types"
import { IRegistrationLocationUpdateFormFields } from "."

interface Props extends Omit<ComponentProps<typeof DrawerForm>, "children"> {
	registration: Registration
	handleSubmit: FormikOnSubmit<IRegistrationLocationUpdateFormFields>
	nativeVillageList: Village[]
	nativeVillageSearchText: string
	setNativeVillageSearchText: (value: string) => void
	currentVillageList: Village[]
	currentVillageSearchText: string
	setCurrentVillageSearchText: (value: string) => void
}

export const RegistrationLocationUpdateDrawerFormView: FC<Props> = ({
	registration: { location: registrationLocation },
	handleSubmit,
	nativeVillageList,
	currentVillageList,
	setNativeVillageSearchText,
	setCurrentVillageSearchText,
	...rest
}) => {
	const [localNativeVillageId, setLocalNativeVillageId] = useState(
		registrationLocation?.nativeVillage?.id,
	)
	const [localCurrentVillageId, setLocalCurrentVillageId] = useState(
		registrationLocation?.currentVillage?.id,
	)
	if (!registrationLocation) return null

	return (
		<Formik<IRegistrationLocationUpdateFormFields>
			initialValues={{
				nativeVillageId: localNativeVillageId,
				currentVillageId: localCurrentVillageId,
				currentVillageText: registrationLocation.currentVillageText,
			}}
			onSubmit={handleSubmit}
			enableReinitialize={true}
		>
			{({ values, isSubmitting, handleChange, setFieldValue }) => {
				const nativeVillageOptions = nativeVillageList.map((village) => ({
					value: village.id,
					label:
						getVillageName(village) +
						` (${getVillageName(village, { lang: Language.GUJARATI })})`,
				}))

				const currentVillageOptions = currentVillageList.map((village) => ({
					value: village.id,
					label:
						getVillageName(village) +
						` (${getVillageName(village, { lang: Language.GUJARATI })})`,
				}))

				return (
					<DrawerForm
						size="sm"
						headerLabel="Location Details Update"
						submitLabel="Save"
						isSubmitting={isSubmitting}
						{...rest}
					>
						<Stack maxWidth={"sm"} marginX={"auto"}>
							<Flex gridGap={2}>
								{/* Current Village Text */}
								<FormControl>
									<InputLabel label="Current Village Text" />
									<Input
										name="currentVillageText"
										placeholder="Current Village Text"
										maxLength={50}
										value={values.currentVillageText ?? undefined}
										onChange={handleChange}
									/>
									<ErrorMessage
										component={ErrorMessageField}
										name="currentVillageText"
									/>
								</FormControl>
							</Flex>
							{/* Native Village */}
							<FormControl flex={2}>
								<InputLabel label="Native Village" />
								<ReactSelect
									name="nativeVillageId"
									onChange={(newValue) => {
										setLocalNativeVillageId(
											(newValue as SelectOption).value ?? undefined,
										)
										setFieldValue(
											"nativeVillageId",
											(newValue as SelectOption).value,
										)
									}}
									onInputChange={(newValue) => {
										setNativeVillageSearchText(newValue)
									}}
									value={nativeVillageOptions.find(
										(el) => el.value === values.nativeVillageId,
									)}
									options={nativeVillageOptions}
									isSearchable
								/>
							</FormControl>
							{/* Current Village */}
							<FormControl flex={2}>
								<InputLabel label="Current Village" />
								<ReactSelect
									name="currentVillageId"
									onChange={(newValue) => {
										setLocalCurrentVillageId(
											(newValue as SelectOption).value ?? undefined,
										)
										// setFieldValue(
										// 	"currentVillageId",
										// 	(newValue as SelectOption).value,
										// )
									}}
									onInputChange={(newValue) => {
										setCurrentVillageSearchText(newValue)
									}}
									value={currentVillageOptions.find(
										(el) => el.value === values.currentVillageId,
									)}
									options={currentVillageOptions}
									isSearchable
								/>
							</FormControl>
						</Stack>
					</DrawerForm>
				)
			}}
		</Formik>
	)
}
