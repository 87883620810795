import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Village } from "../../../../domain/entities/village"
import { useAuth, useTalukaListService } from "../../../../domain/hooks"
import { villageAddService } from "../../../../domain/services/village/villageAddService"
import { FormikOnSubmit } from "../../../../utils/types"
import { IVillageAddFormFields } from "./IVillageAddFormFields"
import { VillageAddDrawerFormView } from "./VillageAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof VillageAddDrawerFormView>,
		"handleSubmit" | "talukaList"
	> {
	onSuccess: (village: Village) => void
}

export const VillageAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IVillageAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const village = await villageAddService(values, token)
				onSuccess(village)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchTalukaList, talukaList } = useTalukaListService()

	useEffect(() => {
		fetchTalukaList()
	}, [fetchTalukaList])

	return (
		<VillageAddDrawerFormView
			handleSubmit={handleSubmit}
			talukaList={talukaList}
			{...rest}
		/>
	)
}
