import { FC } from "react"
import { Country } from "../../../../domain/entities/country"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { CountryListView } from "./CountryListView"

interface Props {
	list: Country[]
	isLoading: boolean
	onUpdate: (country: Country) => void
	onDelete: (country: Country) => void
	onAddClick: () => void
}

export const CountryListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return (
			<BlankStateSection message="No Sub Castes found." onAddClick={onAddClick} />
		)
	}

	return <CountryListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
