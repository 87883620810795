import { Review } from "src/domain/entities/review"
import { MultiLangText } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	registrationId: string
	note: MultiLangText
}

export async function reviewRejectApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/review/reject/v1"
	const requester = new ApiRequester<RequestDataShape, Review>(endpoint)
	return await requester.sendRequest(payload, token)
}
