import { MultiLangText } from "src/utils/types"
import { State } from "../../entities/state"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	countryId: string
	update: {
		name?: MultiLangText
		countryId?: string
		isApproved?: boolean
	}
}

export async function stateUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/state/update/v1"
	const requester = new ApiRequester<RequestDataShape, State>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
