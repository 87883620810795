import { FC } from "react"
import { District } from "../../../../domain/entities/district"
import { BlankStateSection } from "../../../shared/BlankStateSection"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { DistrictListView } from "./DistrictListView"

interface Props {
	list: District[]
	isLoading: boolean
	onUpdate: (district: District) => void
	onDelete: (district: District) => void
	onAddClick: () => void
}

export const DistrictListController: FC<Props> = ({
	isLoading,
	list,
	onDelete,
	onUpdate,
	onAddClick,
}) => {
	if (isLoading) return <CenteredSpinner />

	if (list.length === 0) {
		return <BlankStateSection message="No Districts found." onAddClick={onAddClick} />
	}

	return <DistrictListView list={list} onUpdate={onUpdate} onDelete={onDelete} />
}
