import { Box, Card, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { Surname } from "../../../../domain/entities/surname"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const SurnameListView: FC<{
	list: Surname[]
	onUpdate: (surname: Surname) => void
	onDelete: (surname: Surname) => void
}> = ({ list, onUpdate, onDelete }) => (
	<>
		<Table size={"sm"} display={{ base: "none", lg: "table" }}>
			<Thead>
				<Tr>
					<Th>Code</Th>
					<Th>Surname</Th>
					<Th>Surname Gujarati</Th>
					<Th>SubCaste</Th>
					<Th isNumeric>Actions</Th>
				</Tr>
			</Thead>
			<Tbody>
				{list.map((surname, i) => (
					<Tr key={i}>
						<Td>{surname.code}</Td>
						<Td>{surname.name.en}</Td>
						<Td>{surname.name.gu}</Td>
						<Td>
							{surname.subCaste?.name?.en +
								` (${surname.subCaste?.name.gu})`}
						</Td>
						<Td isNumeric>
							<EditIconButton onClick={() => onUpdate(surname)} />
							<DeleteIconButton onClick={() => onDelete(surname)} />
						</Td>
					</Tr>
				))}
			</Tbody>
		</Table>

		{list.map((surname, i) => (
			<Card display={{ base: "flex", lg: "none" }} shadow={"md"} m={"2"} key={i}>
				<Box display={"flex"} justifyContent={"space-between"}>
					<Box m={"2"}>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Code:
							</Box>
							<Box ml={"2"}>{surname.code}</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Surname:
							</Box>
							<Box ml={"2"}>{surname.name.en}</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Surname Gujarati:
							</Box>
							<Box ml={"2"} fontSize={"sm"}>
								{surname.name.gu}
							</Box>
						</Flex>
						<Flex align={"center"}>
							<Box fontWeight={"semibold"} fontSize={"md"}>
								Subcast:
							</Box>
							<Box ml={"2"} fontSize={"sm"}>
								{surname.subCaste?.name?.en +
									` (${surname.subCaste?.name.gu})`}
							</Box>
						</Flex>
					</Box>
					<Box m={"1"}>
						<Flex align={"center"}>
							<Box ml={"2"}>
								<EditIconButton onClick={() => onUpdate(surname)} />
								<DeleteIconButton onClick={() => onDelete(surname)} />
							</Box>
						</Flex>
					</Box>
				</Box>
			</Card>
		))}
	</>
)
