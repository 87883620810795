import { User } from "src/domain/entities/user"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	isReviewer?: boolean
	profileId?: string
	registrationId?: string
	fetch?: {
		hashedPassword?: boolean
		profile?: boolean
		profileDetails?: boolean
		registration?: boolean
		registrationDetails?: boolean
	}
	pagination?: RequestPagination
}

export async function userListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/user/list/v1"
	const requester = new ApiRequester<RequestDataShape, User[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
