import { FC } from "react"
import { Navigate } from "react-router-dom"
import { useAuthContext } from "../context/auth"

export const PrivateRoute: FC<{ element: JSX.Element }> = ({ element }) => {
	const { token, setToken } = useAuthContext()
	if (!token) {
		const localStorageToken = localStorage.getItem("token")

		if (localStorageToken) {
			setToken(localStorageToken)
		} else {
			return <Navigate to="/login" />
		}
	}

	return element
}
