import moment from "moment"
import { registrationBasicUpdateApi } from "../../api/registration/registrationBasicUpdateApi"

type PayloadShape = Parameters<typeof registrationBasicUpdateApi>[0]

export async function registrationBasicUpdateService(
	payload: PayloadShape,
	token: string,
) {
	if (payload.update.dateOfBirth) {
		payload.update.dateOfBirth = moment(payload.update.dateOfBirth).unix() * 1000
	}
	return await registrationBasicUpdateApi(payload, token)
}
