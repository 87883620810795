import { MultiLangText } from "src/utils/types"
import { District } from "../../entities/district"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	stateId: string
	update: {
		name?: MultiLangText
		stateId?: string
		isApproved?: boolean
	}
}

export async function districtUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/district/update/v1"
	const requester = new ApiRequester<RequestDataShape, District>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
