import { Profile } from "src/domain/entities/profile"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
}

export async function registrationApproveApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/registration/approve/v1"
	const requester = new ApiRequester<RequestDataShape, Profile>(endpoint)
	return await requester.sendRequest(payload, token)
}
