import { MultiLangText } from "src/utils/types"
import { Village } from "../../entities/village"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	talukaId: string
	update: {
		name?: MultiLangText
		sameAsParent?: boolean
		talukaId?: string
		isApproved?: boolean
	}
}

export async function villageUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/village/update/v1"
	const requester = new ApiRequester<RequestDataShape, Village>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
