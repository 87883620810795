import { User } from "src/domain/entities/user"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	update?: {
		isReviewer?: boolean
	}
}

export async function userUpdateApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/user/update/v1"
	const requester = new ApiRequester<RequestDataShape, User>(endpoint)
	return await requester.sendRequest(payload, token)
}
