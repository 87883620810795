import { Text } from "@chakra-ui/react"
import { FC } from "react"

export const ThePatidarsLogo: FC<{ fontSize?: string; variant?: "light" | "dark" }> = ({
	fontSize,
	variant = "dark",
}) => {
	const gradiantStartColor = variant === "dark" ? "#002A64" : "#368AFF"
	const gradiantEndColor = variant === "dark" ? "#006CEF" : "#D3E7FF"

	return (
		<>
			<Text
				as="span"
				width={"fit-content"}
				bgGradient={`linear(to-br, ${gradiantStartColor}, ${gradiantEndColor})`}
				bgClip="text"
				fontSize={fontSize ?? { base: "5xl", lg: "6xl" }}
				fontWeight={"bold"}
				lineHeight={"shorter"}
			>
				ThePatidars
			</Text>
		</>
	)
}
