import { ComponentProps, FC, useCallback, useEffect } from "react"
import { Taluka } from "../../../../domain/entities/taluka"
import { useAuth, useDistrictListService } from "../../../../domain/hooks"
import { talukaAddService } from "../../../../domain/services/taluka/talukaAddService"
import { FormikOnSubmit } from "../../../../utils/types"
import { ITalukaAddFormFields } from "./ITalukaAddFormFields"
import { TalukaAddDrawerFormView } from "./TalukaAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof TalukaAddDrawerFormView>,
		"handleSubmit" | "districtList"
	> {
	onSuccess: (taluka: Taluka) => void
}

export const TalukaAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<ITalukaAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const taluka = await talukaAddService(values, token)
				onSuccess(taluka)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchDistrictList, districtList } = useDistrictListService()

	useEffect(() => {
		fetchDistrictList()
	}, [fetchDistrictList])

	return (
		<TalukaAddDrawerFormView
			handleSubmit={handleSubmit}
			districtList={districtList}
			{...rest}
		/>
	)
}
