import { Box } from "@chakra-ui/react"
import { FC } from "react"
import { BsApple, BsGoogle, BsPhone } from "react-icons/bs"
import { AuthMethod } from "src/utils/enums"

export const AuthMethodTag: FC<{ authMethod?: AuthMethod }> = ({ authMethod }) => {
	const tagData = {
		background: "",
		icon: <div></div>,
	}

	if (authMethod === AuthMethod.withApple) {
		tagData.background = "#000"
		tagData.icon = <BsApple size={"16px"} color={"#fff"} />
	} else if (authMethod === AuthMethod.withMobile) {
		tagData.background = "#2abd51"
		tagData.icon = <BsPhone size={"16px"} color={"#fff"} />
	} else if (authMethod === AuthMethod.withGoogle) {
		tagData.background = "#1e5dfc"
		tagData.icon = <BsGoogle size={"16px"} color={"#fff"} />
	}

	return (
		<Box
			display={"flex"}
			background={tagData.background}
			borderRadius={"50%"}
			padding={"2"}
			alignItems={"center"}
			justifyContent={"center"}
			width={"fit-content"}
			margin={"auto"}
		>
			{tagData.icon}
		</Box>
	)
}
