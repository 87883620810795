import { Box } from "@chakra-ui/react";
import { FC } from "react";

export const ErrorMessageField: FC<{ children?: JSX.Element }> = ({
  children,
}) => {
  return (
    <Box fontSize="sm" color="red">
      {children}
    </Box>
  );
};
