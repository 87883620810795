import { ComponentProps, FC, useCallback, useEffect } from "react"
import { State } from "../../../../domain/entities/state"
import { useAuth, useCountryListService } from "../../../../domain/hooks"
import { stateAddService } from "../../../../domain/services/state/stateAddService"
import { FormikOnSubmit } from "../../../../utils/types"
import { IStateAddFormFields } from "./IStateAddFormFields"
import { StateAddDrawerFormView } from "./StateAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof StateAddDrawerFormView>,
		"handleSubmit" | "countryList"
	> {
	onSuccess: (state: State) => void
}

export const StateAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IStateAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const state = await stateAddService(values, token)
				onSuccess(state)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchCountryList, countryList } = useCountryListService()

	useEffect(() => {
		fetchCountryList()
	}, [fetchCountryList])

	return (
		<StateAddDrawerFormView
			handleSubmit={handleSubmit}
			countryList={countryList}
			{...rest}
		/>
	)
}
