import { useCallback, useState } from "react"
import { SubCaste } from "../entities/subCaste"
import { subCasteListService } from "../services/subCaste/subCasteListService"
import { useAuth } from "./useAuth"

export function useSubCasteListService(): {
	subCasteList: SubCaste[]
	isLoading: boolean
	fetchSubCasteList: (
		filter?: Parameters<typeof subCasteListService>[0],
	) => Promise<void>
} {
	const { token } = useAuth()
	const [subCasteList, setSubCasteList] = useState<SubCaste[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchSubCasteList = useCallback(
		async (filter: Parameters<typeof subCasteListService>[0] = {}) => {
			setIsLoading(true)
			const data = await subCasteListService(filter, token)
			setSubCasteList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { subCasteList, isLoading, fetchSubCasteList }
}
