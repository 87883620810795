import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { Country } from "../../../../domain/entities/country"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const CountryListView: FC<{
	list: Country[]
	onUpdate: (country: Country) => void
	onDelete: (country: Country) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((country, i) => (
				<Tr key={i}>
					<Td>{country.name.en}</Td>
					<Td>{country.name.gu}</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(country)} />
						<DeleteIconButton onClick={() => onDelete(country)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
