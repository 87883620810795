import { registrationLocationUpdateApi } from "../../api/registration/registrationLocationUpdateApi"

type PayloadShape = Parameters<typeof registrationLocationUpdateApi>[0]

export async function registrationLocationUpdateService(
	payload: PayloadShape,
	token: string,
) {
	return await registrationLocationUpdateApi(payload, token)
}
