import { RequestPagination } from "src/utils/types"
import { District } from "../../entities/district"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	isApproved?: boolean
	stateId?: string
	countryId?: string
	fetch?: { state?: boolean; stateCountry?: boolean }
	pagination?: RequestPagination
}

export async function districtListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/district/list/v1"
	const requester = new ApiRequester<RequestDataShape, District[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
