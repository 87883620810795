import { registrationListApi } from "../../api/registration/registrationListApi"
import { Registration } from "../../entities/registration"

type PayloadShape = Parameters<typeof registrationListApi>[0]

export async function registrationListService(
	payload: PayloadShape,
	token?: null | string,
) {
	const registration = await registrationListApi(payload, token)
	return registration as NonNullable<Registration[]>
}
