import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
} from "@chakra-ui/react"
import moment from "moment"
import React, { FC, useCallback, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { BsPerson, BsX } from "react-icons/bs"
import { Profile } from "src/domain/entities/profile"
import { ProfileFamily } from "src/domain/entities/profileFamily"
import { useAuth, useProfileListService } from "src/domain/hooks"
import { profileFamilyGetService } from "src/domain/services/profileFamily/profileFamilyGetService"
import { getMaritalStatusName } from "src/utils/enums"
import { formatDate, getFullName, getVillageName } from "src/utils/helpers"
import { ImageViewModalController } from "../ui/ImageViewModalController"

interface Props {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	profile: Profile
	handleProfileClick: (profile: Profile) => void
}

export const ProfileDetailsModal: FC<Props> = ({
	isOpen,
	setIsOpen,
	profile,
	handleProfileClick,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
			<ModalOverlay />
			<ModalContent rounded={"2xl"} overflow="hidden">
				<ModalBody p="0">
					<ProfileDetails
						profile={profile}
						handleProfileClick={handleProfileClick}
						setIsOpen={setIsOpen}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

const ProfileFamilyListItem: React.FC<{
	profile?: Profile
	relation: "Mother" | "Father" | "Sibling" | "Spouse" | "Child"
	handleProfileClick?: (profile: Profile) => void
}> = ({ profile, relation, handleProfileClick }) => {
	if (!profile) return null

	return (
		<>
			<Flex
				_hover={{
					backgroundColor: "gray.100",
				}}
				cursor="pointer"
				p="2"
				onClick={() => handleProfileClick?.(profile)}
			>
				<Box>
					{profile?.profileImageUrl ? (
						<Image
							style={{ aspectRatio: "1" }}
							src={profile?.profileImageUrl}
							height="30px"
							rounded={"md"}
						/>
					) : (
						<Flex
							height={30}
							width={30}
							bgColor="gray.100"
							rounded={"lg"}
							justify="center"
							align={"center"}
							color="gray.500"
						>
							<BsPerson size={20} />
						</Flex>
					)}
				</Box>
				<Box flex={1} ml="2">
					<Box lineHeight={1}>{getFullName(profile)}</Box>
					<Box fontSize={"xs"} color="gray.500">
						{relation}
					</Box>
				</Box>
			</Flex>
		</>
	)
}

export const ProfileDetails: FC<{
	profile: Profile
	handleProfileClick: (profile: Profile) => void
	setIsOpen?: (isOpen: boolean) => void
}> = ({ profile, handleProfileClick, setIsOpen }) => {
	const { fetchProfileList, isLoading, profileList } = useProfileListService()
	const { token } = useAuth()

	const [profileFamilyLoading, setProfileFamilyLoading] = useState(false)
	const [selectedViewImageUrl, setSelectedViewImageUrl] = useState<string | null>(null)
	const [profileFamily, setProfileFamily] = useState<ProfileFamily | null>(null)

	const fetchProfileDetails = useCallback(async () => {
		try {
			await fetchProfileList({
				id: profile.id,
				fetch: {
					surname: {
						subCaste: true,
					},
					nativeVillage: {
						district: true,
						taluka: true,
					},
					currentVillage: {
						country: true,
						state: true,
						district: true,
						taluka: true,
					},
				},
			})
		} catch (err) {
			console.error(err)
		}
	}, [fetchProfileList, profile])

	const fetchProfileFamilyDetails = useCallback(async () => {
		try {
			setProfileFamilyLoading(true)
			var response = await profileFamilyGetService(
				{
					profileId: profile.id,
					father: true,
					mother: true,
					spouse: true,
					children: true,
					siblings: true,
					fetch: {
						surname: {},
					},
				},
				token,
			)

			setProfileFamily(response)
		} catch (err) {
			console.error(err)
		} finally {
			setProfileFamilyLoading(false)
		}
	}, [profile, token])

	useEffect(() => {
		fetchProfileDetails()
	}, [fetchProfileDetails])

	useEffect(() => {
		fetchProfileFamilyDetails()
	}, [fetchProfileFamilyDetails])

	const profileDetails = profileList[0]

	return (
		<>
			<Box backgroundColor={"gray.800"} p={{ base: "3", lg: "8" }}>
				<Flex>
					<Flex
						borderRadius={"full"}
						overflow="hidden"
						w="100px"
						h="100px"
						flex={"none"}
						bgColor="gray.100"
						align={"center"}
						justify={"center"}
					>
						{isLoading ? (
							<AiOutlineLoading3Quarters />
						) : profile?.profileImageUrl ? (
							<Image
								src={profileDetails?.profileImageUrl}
								alt="Profile Picture"
								objectFit="cover"
								onClick={() =>
									setSelectedViewImageUrl(
										profileDetails.profileImageUrl!,
									)
								}
							/>
						) : (
							<BsPerson size={20} />
						)}
					</Flex>
					<Box flex={1} ml="6">
						<Box
							color={"white"}
							fontSize="2xl"
							lineHeight={"1.3"}
							fontWeight="bold"
						>
							{getFullName(profileDetails)}
						</Box>
						<Flex align={"center"} columnGap="2" mt="2">
							<Box
								color={"gray.300"}
								fontSize="sm"
								textTransform={"capitalize"}
							>
								{profileDetails?.gender}
							</Box>
							<Box
								backgroundColor={"gray.300"}
								w="5px"
								h="5px"
								borderRadius={"full"}
							></Box>

							<Box
								color={"gray.300"}
								fontSize="sm"
								textTransform={"capitalize"}
							>
								{moment(profileDetails?.dateOfBirth).format(
									"DD MMM YYYY",
								)}
							</Box>
						</Flex>

						<Box
							mt="2"
							color={"gray.300"}
							fontSize="sm"
							textTransform={"capitalize"}
						>
							{profileDetails?.code}
						</Box>
					</Box>
					<Box display={{ base: "block", lg: "none" }}>
						<Button
							bgColor={"transparent"}
							_hover={{
								bgColor: "gray.700",
							}}
							padding={2}
							onClick={() => setIsOpen?.(false)}
						>
							<BsX size={30} color={"white"} />
						</Button>
					</Box>
				</Flex>
			</Box>
			<Flex
				m={{ base: "3", lg: "4" }}
				gap={{ base: "4", lg: "6" }}
				direction={"column"}
			>
				<Flex direction={"column"}>
					<Box color={"gray.400"} fontWeight="semibold">
						INFO
					</Box>
					<Flex gap="2" direction={"column"}>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								ID
							</Box>
							<Box color={"gray.900"} textTransform={"capitalize"}>
								{profileDetails?.id}
							</Box>
						</Box>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								Code
							</Box>
							<Box color={"gray.900"} textTransform={"capitalize"}>
								{profileDetails?.code}
							</Box>
						</Box>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								Created At
							</Box>
							<Box>
								{formatDate(profile.createdAt, {
									includeTime: true,
								})}
							</Box>
						</Box>
					</Flex>
				</Flex>
				<Flex direction={"column"}>
					<Box color={"gray.400"} fontWeight="semibold">
						BASIC
					</Box>
					<Flex gap="2" direction={"column"}>
						<Flex>
							<Box flex={1}>
								<Box color={"gray.700"} fontSize="sm">
									Date of Birth
								</Box>
								<Box color={"gray.900"} textTransform={"capitalize"}>
									{moment(profileDetails?.dateOfBirth).format(
										"DD MMM YYYY",
									)}
								</Box>
							</Box>
							<Box flex={1}>
								<Box color={"gray.700"} fontSize="sm">
									Gender
								</Box>
								<Box color={"gray.900"} textTransform={"capitalize"}>
									{profileDetails?.gender}
								</Box>
							</Box>
						</Flex>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								Marital Status
							</Box>
							<Box color={"gray.900"} textTransform={"capitalize"}>
								{getMaritalStatusName(profileDetails?.maritalStatus)}
							</Box>
						</Box>
					</Flex>
				</Flex>
				<Flex direction={"column"}>
					<Box color={"gray.400"} fontWeight="semibold">
						LOCATION
					</Box>
					<Flex gap="2" direction={"column"}>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								Native village
							</Box>
							<Box color={"gray.900"} textTransform={"capitalize"}>
								{getVillageName(profileDetails?.nativeVillage)}
							</Box>
						</Box>
						<Box>
							<Box color={"gray.700"} fontSize="sm">
								Current city/village
							</Box>
							<Box color={"gray.900"} textTransform={"capitalize"}>
								{getVillageName(profileDetails?.currentVillage)}
							</Box>
						</Box>
					</Flex>
				</Flex>
				{profileFamily ? (
					<Flex direction={"column"}>
						<Box color={"gray.400"} fontWeight="semibold">
							FAMILY
						</Box>
						{profileFamilyLoading ? (
							<AiOutlineLoading3Quarters />
						) : (
							<Flex direction={"column"}>
								<ProfileFamilyListItem
									profile={profileFamily.mother}
									relation="Mother"
									handleProfileClick={handleProfileClick}
								/>
								<ProfileFamilyListItem
									profile={profileFamily.father}
									relation="Father"
									handleProfileClick={handleProfileClick}
								/>
								<ProfileFamilyListItem
									profile={profileFamily.spouse?.profile}
									relation="Spouse"
									handleProfileClick={handleProfileClick}
								/>
								{profileFamily.children
									?.sort((a, b) => a.gender.length - b.gender.length)
									.map((child) => (
										<ProfileFamilyListItem
											profile={child}
											relation="Child"
											handleProfileClick={handleProfileClick}
										/>
									))}
								{profileFamily.siblings
									?.sort((a, b) => a.gender.length - b.gender.length)
									.map((sibling) => (
										<ProfileFamilyListItem
											profile={sibling}
											relation="Sibling"
											handleProfileClick={handleProfileClick}
										/>
									))}
							</Flex>
						)}
					</Flex>
				) : null}
			</Flex>
			{selectedViewImageUrl != null ? (
				<ImageViewModalController
					isOpen={selectedViewImageUrl != null}
					setIsOpen={() => setSelectedViewImageUrl(null)}
					image={
						<Image
							src={selectedViewImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
		</>
	)
}
