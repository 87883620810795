import { ComponentProps, FC, useCallback, useEffect } from "react"
import { District } from "../../../../domain/entities/district"
import { useAuth, useStateListService } from "../../../../domain/hooks"
import { districtUpdateService } from "../../../../domain/services/district/districtUpdateService"
import { FormikOnSubmit } from "../../../../utils/types"
import { IDistrictUpdateFormFields } from "./IDistrictUpdateFormFields"
import { DistrictUpdateDrawerFormView } from "./DistrictUpdateDrawerFormView"

type Props = Omit<
	ComponentProps<typeof DistrictUpdateDrawerFormView>,
	"handleSubmit" | "stateList"
> & {
	onSuccess?: (district: District) => void
}

export const DistrictUpdateDrawerFormController: FC<Props> = ({
	district,
	onSuccess,
	...rest
}) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDistrictUpdateFormFields>>(
		async (values, { setErrors }) => {
			try {
				if (!district.state?.id) return
				const updatedDistrict = await districtUpdateService(
					{ id: district.id, stateId: district.state.id, update: values },
					token,
				)
				onSuccess && onSuccess(updatedDistrict)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ name: message })
			}
		},
		[onSuccess, rest, token, district.id, district.state?.id],
	)
	const { fetchStateList, stateList } = useStateListService()

	useEffect(() => {
		fetchStateList()
	}, [fetchStateList])

	return (
		<DistrictUpdateDrawerFormView
			district={district}
			stateList={stateList}
			handleSubmit={handleSubmit}
			{...rest}
		/>
	)
}
