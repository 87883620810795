import { Registration } from "src/domain/entities/registration"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		nativeVillageId?: string
		currentVillageId?: string | null
		currentVillageText?: string | null
	}
}

export async function registrationLocationUpdateApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/registration/location/update/v1"
	const requester = new ApiRequester<RequestDataShape, Registration>(endpoint)
	return await requester.sendRequest(payload, token)
}
