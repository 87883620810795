import { Profile } from "src/domain/entities/profile"
import { Gender, MaritalStatus } from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	nativeVillageId?: string
	currentVillageId?: string
	reviewedById?: string
	subCasteId?: string
	surnameId?: string
	isAlive?: boolean
	isBornNonPatidar?: boolean
	isMarriedToNonPatidar?: boolean
	isReviewPending?: boolean
	isVerified?: boolean
	gender?: Gender
	maritalStatus?: MaritalStatus
	fetch?: {
		nativeVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		currentVillage?: {
			taluka?: boolean
			district?: boolean
			state?: boolean
			country?: boolean
		}
		surname?: {
			subCaste?: boolean
		}
		reviewedBy?: {
			details?: boolean
		}
	}
	sortingFields?: {
		firstName?: {
			orderBy: "ASC" | "DESC"
		}
	}
	pagination?: RequestPagination
}

export async function profileListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/profile/list/v1"
	const requester = new ApiRequester<RequestDataShape, Profile[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
