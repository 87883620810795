import { Box, Flex } from "@chakra-ui/react"
import { FC } from "react"
import { AiFillHome } from "react-icons/ai"
import { BsBack, BsBoxArrowLeft, BsPeople, BsPinMap } from "react-icons/bs"
import { Header } from "../shared/Header"
import { NavItem, SideBar } from "../shared/SideBar"

const items: NavItem[] = [
	{ name: "Dashboard", link: "/", icon: AiFillHome },
	{ name: "Registrations", link: "/registrations", icon: BsPeople },
	{ name: "Surnames", link: "/surnames", icon: BsBack },
	{
		name: "Locations",
		link: "/locations",
		icon: BsPinMap,
		subItems: [
			{ name: "Countries", link: "/countries", icon: BsPinMap },
			{ name: "States", link: "/states", icon: BsPinMap },
			{ name: "Districts", link: "/districts", icon: BsPinMap },
			{ name: "Talukas", link: "/talukas", icon: BsPinMap },
			{ name: "Villages", link: "/villages", icon: BsPinMap },
		],
	},

	{ name: "Logout", link: "/logout", icon: BsBoxArrowLeft },
]

export const DashboardWrapper: FC<{
	children?: (JSX.Element | null)[] | JSX.Element | null
}> = ({ children }) => {
	return (
		<Flex height="100vh" overflowY="hidden" direction={{ base: "column", lg: "row" }}>
			<Header items={items} />
			<SideBar items={items} />
			<Box
				width="100%"
				overflowY="auto"
				px={{ lg: 6 }}
				py={{ lg: 2 }}
				mt={{ base: "79px", lg: "0" }}
			>
				{children}
			</Box>
		</Flex>
	)
}
