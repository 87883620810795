import { MultiLangText } from "src/utils/types"
import { State } from "../../entities/state"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	name: MultiLangText
	countryId: string
}

export async function stateAddApi(payload: RequestDataShape, token: string) {
	const endpoint = "/state/add/v1"
	const requester = new ApiRequester<RequestDataShape, State>(endpoint)
	return await requester.sendRequest(payload, token)
}
