import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { userAuthMobileLoginService } from "src/domain/services/user/userAuthMobileLoginService"
import { ILoginFormFields, LoginFormView } from "."
import { FormikOnSubmit } from "../../../../utils/types"
import { useAuthContext } from "../../../context/auth"

export const LoginFormContainer: FC<{ children?: JSX.Element }> = () => {
	const { setToken } = useAuthContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback<FormikOnSubmit<ILoginFormFields>>(
		async (values, { setErrors }) => {
			try {
				const { token } = await userAuthMobileLoginService(values)
				setToken(token)

				navigate("/")
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({ password: message })
			}
		},
		[navigate, setToken],
	)

	return <LoginFormView handleSubmit={handleSubmit} />
}
