import { useCallback, useState } from "react"
import { Mobile } from "../entities/mobile"
import { mobileListService } from "../services/mobile/mobileListService"
import { useAuth } from "./useAuth"

export function useMobileListService(): {
	mobileList: Mobile[]
	isLoading: boolean
	fetchMobileList: (filter?: Parameters<typeof mobileListService>[0]) => Promise<void>
} {
	const { token } = useAuth()
	const [mobileList, setMobileList] = useState<Mobile[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const fetchMobileList = useCallback(
		async (filter: Parameters<typeof mobileListService>[0] = {}) => {
			setIsLoading(true)
			const data = await mobileListService(filter, token)
			setMobileList(data)
			setIsLoading(false)
		},
		[token],
	)

	return { mobileList, isLoading, fetchMobileList }
}
