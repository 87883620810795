import { usePagination } from "@ajna/pagination"
import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputRightAddon,
	Tag,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { BsSearch } from "react-icons/bs"
import { useSearchParams } from "react-router-dom"
import ReactSelect from "react-select"
import { CustomPagination } from "src/components/shared/CustomPagination"
import { InputLabel } from "src/components/ui/InputLabel"
import { District } from "src/domain/entities/district"
import { Taluka } from "src/domain/entities/taluka"
import { fetchGujaratiSuggestions } from "src/utils/helpers"

import { Village } from "../../../domain/entities/village"
import {
	useDistrictListService,
	useTalukaListService,
	useVillageListService,
} from "../../../domain/hooks"
import { DashboardWrapper } from "../../wrappers/DashboardWrapper"
import { VillageAddDrawerFormController } from "./VillageAddDrawerForm"
import { VillageDeleteDialogController } from "./VillageDeleteDialogController"
import { VillageListController } from "./VillageList"
import { VillageUpdateDrawerFormController } from "./VillageUpdateDrawerForm"

export const VillagesPage: FC = () => {
	const { villageList, isLoading, fetchVillageList } = useVillageListService()
	const [isVillageDeleteDialogOpen, setIsVillageDeleteDialogOpen] = useState(false)
	const villageAddDrawerDisclosure = useDisclosure()
	const villageUpdateDrawerDisclosure = useDisclosure()

	const { talukaList, fetchTalukaList } = useTalukaListService()
	const { districtList, fetchDistrictList } = useDistrictListService()

	const [selectedTaluka, setSelectedTaluka] = useState<Taluka>()
	const [selectedDistrict, setSelectedDistrict] = useState<District>()

	const [params, setParams] = useSearchParams()

	const queryPage = params.get("page")
	const pagination = usePagination({
		initialState: { currentPage: +(queryPage ?? 1), pageSize: 15 },
	})

	const querySearch = params.get("search")
	const [searchText, setSearchText] = useState(querySearch ?? "")
	const [gujaratiSuggestions, setGujaratiSuggestions] = useState<string[]>([])

	const getGujaratiSuggestions = useCallback(async (searchText: string) => {
		const suggestions = await fetchGujaratiSuggestions(searchText)
		setGujaratiSuggestions(suggestions)
	}, [])

	const fetchVillages = useCallback(async () => {
		setParams("page=" + pagination.currentPage)
		if (searchText) setParams("search=" + searchText)

		return await fetchVillageList({
			fetch: { talukaDistrictStateCountry: true },
			search: searchText,
			talukaId: selectedTaluka?.id,
			districtId: selectedDistrict?.id,
			pagination: {
				page: pagination.currentPage,
				limit: 15,
			},
		})
	}, [
		fetchVillageList,
		pagination.currentPage,
		selectedTaluka?.id,
		selectedDistrict?.id,
		searchText,
		setParams,
	])

	useEffect(() => {
		fetchVillages()
	}, [fetchVillages])

	useEffect(() => {
		fetchTalukaList()
	}, [fetchTalukaList])

	useEffect(() => {
		fetchDistrictList()
	}, [fetchDistrictList])

	const updateVillageRef = useRef<Village>()
	const deleteVillageRef = useRef<Village>()

	const talukaOptions: {
		label: string
		value?: Taluka
	}[] = talukaList.map((taluka) => ({
		label: taluka.name.en,
		value: taluka,
	}))

	talukaOptions.unshift({
		label: "All",
		value: undefined,
	})

	const districtOptions: {
		label: string
		value?: District
	}[] = districtList.map((district) => ({
		label: district.name.en,
		value: district,
	}))

	districtOptions.unshift({
		label: "All",
		value: undefined,
	})

	return (
		<DashboardWrapper>
			<Box padding={2} mt={{ base: "1", lg: "0" }}>
				<Flex justifyContent="space-between" alignItems="center">
					<Text fontSize="2xl" fontWeight="bold">
						Villages
					</Text>
					<Button
						size="sm"
						onClick={villageAddDrawerDisclosure.onOpen}
						colorScheme="blue"
					>
						Add Village
					</Button>
				</Flex>
			</Box>
			<Box px={2} mb={4}>
				<Flex
					gridColumnGap={2}
					align="flex-end"
					direction={{ base: "column", lg: "row" }}
				>
					<Box width={{ base: "full", lg: "fit-content" }}>
						<InputGroup>
							<Input
								value={searchText}
								onChange={(e) => {
									pagination.setCurrentPage(1)
									setSearchText(e.target.value)
									getGujaratiSuggestions(e.target.value)
								}}
								type="text"
								placeholder="Search"
							/>
							<InputRightAddon>
								<BsSearch />
							</InputRightAddon>
						</InputGroup>
						{gujaratiSuggestions.map((el, i) => (
							<Tag
								key={i}
								colorScheme={"green"}
								backgroundColor={"green.50"}
								variant="outline"
								_hover={{
									backgroundColor: "green.100",
								}}
								cursor="pointer"
								margin={0.5}
								onClick={() => {
									pagination.setCurrentPage(1)
									setSearchText(el)
									setGujaratiSuggestions([])
								}}
							>
								{el}
							</Tag>
						))}
					</Box>
					{/* Taluka */}
					<Box width={{ base: "full", lg: "20%" }} my={{ base: "2", lg: "0" }}>
						<InputLabel label="Taluka" />
						<ReactSelect
							name="talukaId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedTaluka(val?.value)
							}}
							value={talukaOptions.find(
								(el) => el.value?.id === selectedTaluka?.id,
							)}
							options={talukaOptions}
						/>
					</Box>
					{/* District */}
					<Box width={{ base: "full", lg: "20%" }} mb={{ base: "2", lg: "0" }}>
						<InputLabel label="District" />
						<ReactSelect
							name="districtId"
							onChange={(val) => {
								pagination.setCurrentPage(1)
								setSelectedDistrict(val?.value)
							}}
							value={districtOptions.find(
								(el) => el.value?.id === selectedDistrict?.id,
							)}
							options={districtOptions}
						/>
					</Box>
				</Flex>
			</Box>
			<VillageListController
				list={villageList}
				isLoading={isLoading}
				onUpdate={(village: Village) => {
					updateVillageRef.current = village
					villageUpdateDrawerDisclosure.onOpen()
				}}
				onDelete={(village: Village) => {
					deleteVillageRef.current = village
					setIsVillageDeleteDialogOpen(true)
				}}
				onAddClick={villageAddDrawerDisclosure.onOpen}
			/>
			<CustomPagination
				pagination={pagination}
				isNextDisabled={villageList.length === 0}
			/>
			{villageAddDrawerDisclosure.isOpen ? (
				<VillageAddDrawerFormController
					{...villageAddDrawerDisclosure}
					onSuccess={() => fetchVillages()}
				/>
			) : null}
			{updateVillageRef.current && villageUpdateDrawerDisclosure.isOpen ? (
				<VillageUpdateDrawerFormController
					{...villageUpdateDrawerDisclosure}
					village={updateVillageRef.current}
					onSuccess={() => fetchVillages()}
				/>
			) : null}
			{deleteVillageRef.current && isVillageDeleteDialogOpen ? (
				<VillageDeleteDialogController
					isOpen={isVillageDeleteDialogOpen}
					setIsOpen={setIsVillageDeleteDialogOpen}
					village={deleteVillageRef.current}
					onSuccess={() => fetchVillages()}
				/>
			) : null}
		</DashboardWrapper>
	)
}
