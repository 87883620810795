import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	mobile: {
		countryCode: string
		mobileNo: string
	}
	password: string
	deviceId: string
}

interface ResponseDataShape {
	token: string
	tokenVersion: number
	refreshToken: string
	refreshTokenVersion: number
	validTill: number
}

export async function userAuthMobileLoginApi(payload: RequestDataShape) {
	const endpoint = "/user/auth/mobile/login/v1"
	const requester = new ApiRequester<RequestDataShape, ResponseDataShape>(endpoint)
	const response = await requester.sendRequest(payload)
	return response
}
