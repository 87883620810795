import { RequestPagination } from "src/utils/types"
import { State } from "../../entities/state"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	isApproved?: boolean
	countryId?: string
	fetch?: { country?: boolean }
	pagination?: RequestPagination
}

export async function stateListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/state/list/v1"
	const requester = new ApiRequester<RequestDataShape, State[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
