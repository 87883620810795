import { MultiLangText } from "src/utils/types"
import { Country } from "../../entities/country"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id: string
	update: {
		name?: MultiLangText
		isApproved?: boolean
	}
}

export async function countryUpdateApi(payload: RequestDataShape, token: string) {
	const endpoint = "/country/update/v1"
	const requester = new ApiRequester<RequestDataShape, Country>(endpoint)
	const response = await requester.sendRequest(payload, token)
	return response
}
