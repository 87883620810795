import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from "@chakra-ui/react"
import React from "react"
import { ErrorMessageField } from "../ui"

const ConfirmationDialog = ({
	title,
	subtitle,
	errorMessage,
	isOpen,
	cancelRef,
	onCancel,
	onConfirm,
	isLoading,
	extendedBody,
}: {
	title: string
	subtitle?: string | JSX.Element
	errorMessage?: string | null
	isOpen: boolean
	cancelRef?: React.MutableRefObject<null>
	onCancel?: Function
	onConfirm?: Function
	isLoading?: boolean
	extendedBody?: JSX.Element
}) => {
	const newRef = React.useRef<null>(null)

	if (!cancelRef) {
		cancelRef = newRef
	}

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={() => (onCancel ? onCancel() : null)}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader
						fontSize="lg"
						fontWeight="bold"
						data-testid="title"
					>
						{title}
					</AlertDialogHeader>

					<AlertDialogBody>
						{subtitle ??
							`Are you sure? You can't undo this action afterwards.`}

						{errorMessage && (
							<ErrorMessageField>
								<>{errorMessage}</>
							</ErrorMessageField>
						)}

						{extendedBody}
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={() => (onCancel ? onCancel() : null)}
							data-testid="cancel-btn"
						>
							Not Now
						</Button>
						<Button
							colorScheme="red"
							onClick={() => (onConfirm ? onConfirm() : null)}
							ml={3}
							data-testid="delete-btn"
							isLoading={isLoading}
							isDisabled={isLoading}
						>
							Confirm
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}

export default ConfirmationDialog
