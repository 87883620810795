import { FC, useCallback, useState } from "react"
import { Country } from "src/domain/entities/country"
import { useAuth } from "../../../domain/hooks"
import { countryDeleteService } from "../../../domain/services/country/countryDeleteService"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	country: Country
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const CountryDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	country,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleCountryDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await countryDeleteService({ id: country.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [country, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Country: ${country.name.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleCountryDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
