import { RequestPagination } from "src/utils/types"
import { Village } from "../../entities/village"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	isApproved?: boolean
	sameAsParent?: boolean
	talukaId?: string
	districtId?: string
	stateId?: string
	countryId?: string
	fetch?: {
		taluka?: boolean
		talukaDistrict?: boolean
		talukaDistrictState?: boolean
		talukaDistrictStateCountry?: boolean
	}
	pagination?: RequestPagination
}

export async function villageListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/village/list/v1"
	const requester = new ApiRequester<RequestDataShape, Village[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
