import { Box, Button, Flex } from "@chakra-ui/react"
import { FC } from "react"

export const BlankStateSection: FC<{ message?: string; onAddClick?: () => void }> = ({
	message,
	onAddClick,
}) => {
	return (
		<Flex justifyContent="center" alignItems="center" height={300} direction="column">
			<Box marginY={2} color="gray.600">
				{message ?? "No content found."}
			</Box>
			{onAddClick && (
				<Box>
					<Button size="sm" onClick={onAddClick} colorScheme="blue">
						Add now
					</Button>
				</Box>
			)}
		</Flex>
	)
}
