export enum Language {
	ENGLISH = "en",
	GUJARATI = "gu",
}

export enum Gender {
	MALE = "male",
	FEMALE = "female",
}

export enum MaritalStatus {
	SINGLE = "single",
	MARRIED = "married",
	ENGAGED = "engaged",
	SEPARATED = "separated",
	DIVORCED = "divorced",
	WIDOW = "widow",
}

export enum RegistrationStep {
	BASIC = "basic",
	LOCATION = "location",
	IDENTITY = "identity",
}

export enum ReviewStatus {
	PENDING = "pending",
	IN_REVIEW = "in_review",
	REJECTED = "rejected",
	APPROVED = "approved",
}

export enum AuthMethod {
	withApple = "withApple",
	withGoogle = "withGoogle",
	withMobile = "withMobile",
}

export function getMaritalStatusName(status: MaritalStatus) {
	switch (status) {
		case MaritalStatus.SINGLE:
			return "Single"
		case MaritalStatus.MARRIED:
			return "Married"
		case MaritalStatus.ENGAGED:
			return "Engaged"
		case MaritalStatus.SEPARATED:
			return "Separated"
		case MaritalStatus.DIVORCED:
			return "Divorced"
		case MaritalStatus.WIDOW:
			return "Widow"
	}
}

export enum BloodGroup {
	A_POSITIVE = "a_positive",
	A_NEGATIVE = "a_negative",
	B_POSITIVE = "b_positive",
	B_NEGATIVE = "b_negative",
	AB_POSITIVE = "ab_positive",
	AB_NEGATIVE = "ab_negative",
	O_POSITIVE = "o_positive",
	O_NEGATIVE = "o_negative",
}
export function getBloodGroupType(status: BloodGroup) {
	switch (status) {
		case BloodGroup.A_POSITIVE:
			return "A +ve"
		case BloodGroup.A_NEGATIVE:
			return "A -ve"
		case BloodGroup.B_POSITIVE:
			return "B +ve"
		case BloodGroup.B_NEGATIVE:
			return "B -ve"
		case BloodGroup.AB_POSITIVE:
			return "AB +ve"
		case BloodGroup.AB_NEGATIVE:
			return "AB -ve"
		case BloodGroup.O_POSITIVE:
			return "O +ve"
		case BloodGroup.O_NEGATIVE:
			return "O -ve"
	}
}

export enum OccupationType {
	SELF_EMPLOYED = "self_employed",
	SALARIED = "salaried",
}
