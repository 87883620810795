import { Image, useDisclosure, useToast } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ConfirmationDialog from "src/components/shared/ConfirmationDialog"
import { ImageViewModalController } from "src/components/ui/ImageViewModalController"
import { DashboardWrapper } from "src/components/wrappers"
import { Profile } from "src/domain/entities/profile"
import { DomainError } from "src/domain/errors"
import { useAuth, useRegistrationListService } from "src/domain/hooks"
import { useMobileListService } from "src/domain/hooks/useMobileListService"
import { profileListService } from "src/domain/services/profile/profileListService"
import { registrationApproveService } from "src/domain/services/registration/registrationApproveService"
import { registrationProfileUnlinkService } from "src/domain/services/registration/registrationProfileUninkService"
import { reviewStartService } from "src/domain/services/review/reviewStartService"
import { getErrorMessage } from "src/utils/helpers"
import { CenteredSpinner } from "../../../shared/CenteredSpinner"
import { SurnameAddDrawerFormController } from "../../SurnamesPage/SurnameAddDrawerForm"
import { LinkProfileModalController } from "./LinkProfileModalController"
import { RegistrationBasicUpdateDrawerFormController } from "./RegistrationBasicUpdateDrawerForm"
import { RegistrationDetailsView } from "./RegistrationDetailsView"
import { RegistrationLocationUpdateDrawerFormController } from "./RegistrationLocationUpdateDrawerForm"
import { RejectReviewDialogController } from "./RejectReviewDialogController"

export const RegistrationDetailsController: FC = () => {
	const {
		registrationList: [registration],
		isLoading,
		fetchRegistrationList,
	} = useRegistrationListService()

	const params = useParams()
	const toast = useToast()
	const { token } = useAuth()

	const fetchRegistrationDetails = useCallback(async () => {
		const id = params["id"]
		if (!id) return

		return await fetchRegistrationList({
			id,
			fetch: {
				surname: true,
				surnameSubCaste: true,
				nativeVillage: true,
				currentVillage: true,
				reviewer: true,
				reviews: true,
				user: true,
			},
		})
	}, [fetchRegistrationList, params])

	const { fetchMobileList, mobileList } = useMobileListService()

	const [isRejectReviewModalOpen, setIsRejectReviewModalOpen] = useState(false)

	const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false)
	const [isIdentityProofModalOpen, setIsIdentityProofModalOpen] = useState(false)

	const registrationBasicUpdateDrawerDisclosure = useDisclosure()
	const registrationLocationUpdateDrawerDisclosure = useDisclosure()
	const surnameAddDrawerDisclosure = useDisclosure()

	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

	const [isLinkProfileModalOpen, setIsLinkProfileModalOpen] = useState(false)

	const handleLinkProfileClick = useCallback(() => {
		setIsLinkProfileModalOpen(true)
	}, [])

	const handleUnLinkProfileClick = useCallback(async () => {
		try {
			await registrationProfileUnlinkService(
				{
					registrationId: registration.id,
				},
				token,
			)
			toast({
				title: "Profile unlinked successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			})
			fetchRegistrationDetails()
		} catch (err) {
			toast({
				title: "Error",
				description: getErrorMessage(err),
				status: "error",
				duration: 3000,
				isClosable: true,
			})
		}
	}, [fetchRegistrationDetails, registration?.id, token, toast])

	const handleStartReview = useCallback(async () => {
		await reviewStartService(
			{
				registrationId: registration.id,
				note: {
					en: "Review started",
					gu: "સમીક્ષા શરૂ થઈ ગઈ છે",
				},
			},
			token,
		)
		await fetchRegistrationDetails()
	}, [token, registration?.id, fetchRegistrationDetails])

	const [isApprovingRegistration, setIsApprovingRegistration] = useState(false)

	const handleApproveRegistration = useCallback(async () => {
		try {
			setIsApprovingRegistration(true)
			await registrationApproveService({ id: registration.id }, token)
			await fetchRegistrationDetails()
			setIsLinkProfileModalOpen(false)
		} catch (err) {
			if (err instanceof DomainError) {
				toast({
					title: err.name,
					description: err.message,
					status: "error",
					position: "bottom-right",
					isClosable: true,
					duration: 10000,
				})
			}
		} finally {
			setIsApprovingRegistration(false)
			setIsConfirmationDialogOpen(false)
		}
	}, [token, registration?.id, toast, fetchRegistrationDetails])

	const [linkedProfile, setLinkedProfile] = useState<Profile | null>(null)

	const fetchLinkedProfileDetails = useCallback(async () => {
		if (!registration?.linkedProfileId) return

		const [profile] = await profileListService(
			{
				id: registration.linkedProfileId,
				fetch: { surname: {} },
			},
			token,
		)

		setLinkedProfile(profile ?? null)
	}, [registration?.linkedProfileId, token])

	useEffect(() => {
		fetchLinkedProfileDetails()
	}, [fetchLinkedProfileDetails])

	useEffect(() => {
		fetchRegistrationDetails()
	}, [fetchRegistrationDetails])

	useEffect(() => {
		if (registration?.user?.id) {
			fetchMobileList({
				userId: registration?.user?.id,
			})
		}
	}, [fetchMobileList, registration?.user?.id])

	return (
		<>
			<DashboardWrapper>
				{isLoading ? (
					<CenteredSpinner />
				) : (
					<RegistrationDetailsView
						registration={registration}
						linkedProfile={linkedProfile}
						onStartReview={handleStartReview}
						onRejectReview={() => setIsRejectReviewModalOpen(true)}
						onProfilePictureClick={() => setIsProfilePictureModalOpen(true)}
						onIdentityProofClick={() => setIsIdentityProofModalOpen(true)}
						onLinkProfileClick={handleLinkProfileClick}
						onUnLinkProfileClick={handleUnLinkProfileClick}
						onBasicUpdateClick={
							registrationBasicUpdateDrawerDisclosure.onOpen
						}
						onLocationUpdateClick={
							registrationLocationUpdateDrawerDisclosure.onOpen
						}
						onNewSurnameClick={surnameAddDrawerDisclosure.onOpen}
						mobile={mobileList[0]}
					/>
				)}
			</DashboardWrapper>
			{isRejectReviewModalOpen ? (
				<RejectReviewDialogController
					isOpen={isRejectReviewModalOpen}
					setIsOpen={setIsRejectReviewModalOpen}
					onSuccess={fetchRegistrationDetails}
					registration={registration}
				/>
			) : null}
			{isProfilePictureModalOpen ? (
				<ImageViewModalController
					isOpen={isProfilePictureModalOpen}
					setIsOpen={setIsProfilePictureModalOpen}
					image={
						<Image
							src={registration.identity?.profileImageUrl}
							alt="profile"
							objectFit="contain"
							maxH="90vh"
							maxW="90vw"
						/>
					}
				/>
			) : null}
			{isIdentityProofModalOpen ? (
				<ImageViewModalController
					isOpen={isIdentityProofModalOpen}
					setIsOpen={setIsIdentityProofModalOpen}
					image={
						<img
							src={registration.identity?.identityProofUrl}
							alt="identity"
						/>
					}
				/>
			) : null}
			{registrationBasicUpdateDrawerDisclosure.isOpen ? (
				<RegistrationBasicUpdateDrawerFormController
					isOpen={registrationBasicUpdateDrawerDisclosure.isOpen}
					onClose={registrationBasicUpdateDrawerDisclosure.onClose}
					registration={registration}
					onSuccess={fetchRegistrationDetails}
				/>
			) : null}
			{registrationLocationUpdateDrawerDisclosure.isOpen ? (
				<RegistrationLocationUpdateDrawerFormController
					isOpen={registrationLocationUpdateDrawerDisclosure.isOpen}
					onClose={registrationLocationUpdateDrawerDisclosure.onClose}
					registration={registration}
					onSuccess={fetchRegistrationDetails}
				/>
			) : null}
			{surnameAddDrawerDisclosure.isOpen ? (
				<SurnameAddDrawerFormController
					isOpen={surnameAddDrawerDisclosure.isOpen}
					onClose={surnameAddDrawerDisclosure.onClose}
					onSuccess={() => {}}
					initialValues={{
						code: "",
						name: {
							en: registration.basic?.customSurname?.text?.en || "",
							gu: registration.basic?.customSurname?.text?.gu || "",
						},
						subCasteId: "",
					}}
				/>
			) : null}
			{isLinkProfileModalOpen ? (
				<LinkProfileModalController
					isOpen={isLinkProfileModalOpen}
					setIsOpen={setIsLinkProfileModalOpen}
					onSuccess={fetchRegistrationDetails}
					registration={registration}
					linkedProfile={linkedProfile}
					setLinkedProfile={setLinkedProfile}
					onApproveReview={() => setIsConfirmationDialogOpen(true)}
				/>
			) : null}
			{isConfirmationDialogOpen ? (
				<ConfirmationDialog
					isOpen={isConfirmationDialogOpen}
					title={`Confirm registration of ${registration.basic?.firstName?.en}?`}
					isLoading={isApprovingRegistration}
					onConfirm={handleApproveRegistration}
					onCancel={() => setIsConfirmationDialogOpen(false)}
				/>
			) : null}
		</>
	)
}
