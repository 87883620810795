import { FC, useCallback, useState } from "react"
import { Surname } from "src/domain/entities/surname"
import { useAuth } from "../../../domain/hooks"
import { surnameDeleteService } from "../../../domain/services/surname/surnameDeleteService"
import { getErrorMessage } from "../../../utils/helpers"
import DeleteItemDialog from "../../shared/DeleteItemDialog"

interface Props {
	surname: Surname
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	onSuccess?: () => void
}

export const SurnameDeleteDialogController: FC<Props> = ({
	isOpen,
	setIsOpen,
	surname,
	onSuccess,
}) => {
	const { token } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const handleSurnameDelete = useCallback(async () => {
		try {
			setIsLoading(true)
			await surnameDeleteService({ id: surname.id }, token)
			setIsOpen(false)
			onSuccess && onSuccess()
		} catch (err) {
			setErrorMessage(getErrorMessage(err))
		} finally {
			setIsLoading(false)
		}
	}, [surname, setIsOpen, token, onSuccess])

	return (
		<DeleteItemDialog
			title={`Delete Surname: ${surname.name.en}`}
			isOpen={isOpen}
			onCancel={() => {
				setIsOpen(false)
				setErrorMessage(null)
			}}
			onDelete={handleSurnameDelete}
			isLoading={isLoading}
			errorMessage={errorMessage}
		/>
	)
}
