import {
	Pagination,
	PaginationContainer,
	PaginationPrevious,
	PaginationPageGroup,
	PaginationNext,
	usePagination,
} from "@ajna/pagination"
import { Box } from "@chakra-ui/react"
import { FC } from "react"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

export const CustomPagination: FC<{
	pagination: ReturnType<typeof usePagination>
	isNextDisabled?: boolean
}> = ({ pagination, isNextDisabled = false }) => {
	return (
		<Box textAlign={"right"} mt={2}>
			<style>
				{`
						.pagination-container {
							justify-content: flex-end;
							margin-right: 1rem;
						}
					`}
			</style>
			<Pagination
				pagesCount={2000}
				currentPage={pagination.currentPage}
				onPageChange={pagination.setCurrentPage}
			>
				<PaginationContainer>
					<PaginationPrevious>
						<BsChevronLeft />
					</PaginationPrevious>
					<PaginationPageGroup>
						<Box maxHeight={"fit-content"} marginY="auto" p={2}>
							{pagination.currentPage}
						</Box>
					</PaginationPageGroup>
					<PaginationNext disabled={isNextDisabled}>
						<BsChevronRight />
					</PaginationNext>
				</PaginationContainer>
			</Pagination>
		</Box>
	)
}
