import { Registration } from "src/domain/entities/registration"
import { Gender, MaritalStatus, RegistrationStep, ReviewStatus } from "src/utils/enums"
import { RequestPagination } from "src/utils/types"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	reviewerId?: string
	subCasteId?: string
	surnameId?: string
	currentVillageId?: string
	nativeVillageId?: string
	gender?: Gender
	maritalStatus?: MaritalStatus
	steps?: RegistrationStep
	reviewStatus?: ReviewStatus
	fetch?: {
		surname?: boolean
		surnameSubCaste?: boolean
		nativeVillage?: boolean
		currentVillage?: boolean
		reviews?: boolean
		reviewer?: boolean
		reviewerDetails?: boolean
		user?: boolean
		userDetails?: boolean
	}
	pagination?: RequestPagination
}

export async function registrationListApi(
	payload: RequestDataShape,
	token?: null | string,
) {
	const endpoint = "/registration/list/v1"
	const requester = new ApiRequester<RequestDataShape, Registration[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
