import { ComponentProps, FC, useCallback, useEffect } from "react"
import { District } from "../../../../domain/entities/district"
import { useAuth, useStateListService } from "../../../../domain/hooks"
import { districtAddService } from "../../../../domain/services/district/districtAddService"
import { FormikOnSubmit } from "../../../../utils/types"
import { IDistrictAddFormFields } from "./IDistrictAddFormFields"
import { DistrictAddDrawerFormView } from "./DistrictAddDrawerFormView"

interface Props
	extends Omit<
		ComponentProps<typeof DistrictAddDrawerFormView>,
		"handleSubmit" | "stateList"
	> {
	onSuccess: (district: District) => void
}

export const DistrictAddDrawerFormController: FC<Props> = ({ onSuccess, ...rest }) => {
	const { token } = useAuth()

	const handleSubmit = useCallback<FormikOnSubmit<IDistrictAddFormFields>>(
		async (values, { setErrors }) => {
			try {
				const district = await districtAddService(values, token)
				onSuccess(district)
				rest.onClose()
			} catch (err) {
				const message =
					err instanceof Error ? err.message : "Something went wrong"
				setErrors({
					name: {
						en: message,
					},
				})
			}
		},
		[onSuccess, rest, token],
	)

	const { fetchStateList, stateList } = useStateListService()

	useEffect(() => {
		fetchStateList()
	}, [fetchStateList])

	return (
		<DistrictAddDrawerFormView
			handleSubmit={handleSubmit}
			stateList={stateList}
			{...rest}
		/>
	)
}
