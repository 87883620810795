import { RequestPagination } from "src/utils/types"
import { Taluka } from "../../entities/taluka"
import { ApiRequester } from "../ApiRequester"

interface RequestDataShape {
	id?: string
	search?: string
	isApproved?: boolean
	sameAsParent?: boolean
	districtId?: string
	stateId?: string
	countryId?: string
	fetch?: {
		district?: boolean
		districtState?: boolean
		districtStateCountry?: boolean
	}
	pagination?: RequestPagination
}

export async function talukaListApi(payload: RequestDataShape, token?: null | string) {
	const endpoint = "/taluka/list/v1"
	const requester = new ApiRequester<RequestDataShape, Taluka[]>(endpoint)
	return await requester.sendRequest(payload, token)
}
