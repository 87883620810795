import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { FC } from "react"

interface Props {
	image: JSX.Element
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
}

export const ImageViewModalController: FC<Props> = ({ isOpen, setIsOpen, image }) => {
	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered size={"3xl"}>
			<ModalOverlay />
			<ModalContent>{image}</ModalContent>
		</Modal>
	)
}
