import { registrationProfileLinkApi } from "../../api/registration/registrationProfileLinkApi"

type PayloadShape = Parameters<typeof registrationProfileLinkApi>[0]

export async function registrationProfileLinkService(
	payload: PayloadShape,
	token: string,
) {
	return await registrationProfileLinkApi(payload, token)
}
