import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { FC } from "react"
import { State } from "../../../../domain/entities/state"
import { DeleteIconButton } from "../../../ui/iconButtons/DeleteIconButton"
import { EditIconButton } from "../../../ui/iconButtons/EditIconButton"

export const StateListView: FC<{
	list: State[]
	onUpdate: (state: State) => void
	onDelete: (state: State) => void
}> = ({ list, onUpdate, onDelete }) => (
	<Table size={"sm"}>
		<Thead>
			<Tr>
				<Th>Name</Th>
				<Th>Name Gujarati</Th>
				<Th>Country</Th>
				<Th isNumeric>Actions</Th>
			</Tr>
		</Thead>
		<Tbody>
			{list.map((state, i) => (
				<Tr key={i}>
					<Td>{state.name.en}</Td>
					<Td>{state.name.gu}</Td>
					<Td>
						<Text fontWeight={500}>{state.country?.name?.en}</Text>
						<Text>{state.country?.name.gu}</Text>
					</Td>
					<Td isNumeric>
						<EditIconButton onClick={() => onUpdate(state)} />
						<DeleteIconButton onClick={() => onDelete(state)} />
					</Td>
				</Tr>
			))}
		</Tbody>
	</Table>
)
